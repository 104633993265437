import React from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { useState } from "react";
import { useForm } from "react-hook-form";

export function Edit() {

  const [formData, setFormData] = useState(null);

  // useForm to handle form submissions and validations
  const { register, handleSubmit, formState: { errors } } = useForm();

  // Function to handle form submission
  const Submit = (data) => {
    setFormData(data); // Save form data to state
    console.log(data); // Log the data (or send to backend)
  };


  return (<>
    <Sidebar />
    <div className="p-4 sm:ml-64">
      <div className="p-4">

          <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-6">Your Profile</h2>

            {/* Form starts here */}
            <form onSubmit={handleSubmit(Submit)}>

              {/* Personal Details Section */}
              <div className="border-b border-gray-300 pb-4 mb-6">
                <h3 className="text-xl font-semibold mb-2">Personal Details</h3>
                <p className="text-sm text-gray-600">Tell us a bit about yourself</p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Your Picture</label>
                  <div className="border-dashed border-2 border-gray-300 p-4 rounded-lg text-center">
                    {/* <button className="bg-gray-200 text-gray-600 px-4 py-2 rounded-md">Upload</button> */}
                    <input
                      type="file"
                      className="bg-gray-200 text-gray-600 px-4 py-2 rounded-md cursor-pointer"
                    />

                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Full Name</label>
                  <input
                    {...register("fullName", { required: "Full Name is required" })}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Saqib Ayaz"
                  />
                  {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName.message}</p>}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Your Organisation</label>
                  <input
                    {...register("organisation")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your Organisation"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Your Designation</label>
                  <input
                    {...register("designation")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your Designation"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Gender</label>
                  <select
                    {...register("gender")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">City</label>
                  <select
                    {...register("city")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                  >
                    <option value="">Select your city</option>
                    <option value="City 1">City 1</option>
                    <option value="City 2">City 2</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">State</label>
                  <select
                    {...register("state")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                  >
                    <option value="">Select your state</option>
                    <option value="State 1">State 1</option>
                    <option value="State 2">State 2</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Country</label>
                  <select
                    {...register("country", { required: "Country is required" })}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                  >
                    <option value="Pakistan">Pakistan</option>
                  </select>
                  {errors.country && <p className="text-red-500 text-sm">{errors.country.message}</p>}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Birthday</label>
                  <input
                    type="date"
                    {...register("birthday")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                  />
                </div>
              </div>

              {/* Education Section */}
              <div className="border-t border-gray-300 pt-6 mt-6">
                <h3 className="text-xl font-semibold mb-2">Education</h3>
                <p className="text-sm text-gray-600">Tell us a bit about your work experience so we can match you with potential opportunities.</p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Your Degree</label>
                  <select
                    {...register("degree")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                  >
                    <option value="Bachelors">Bachelors</option>
                    <option value="Masters">Masters</option>
                    <option value="PhD">PhD</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Field of Study</label>
                  <input
                    {...register("fieldOfStudy")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Computer Science"
                  />
                </div>

                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Your School/University</label>
                  <input
                    {...register("school")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please tell us where you are studying"
                  />
                </div>
              </div>

              {/* Education Section End */}





              {/* Social Details */}
              <div className="border-t border-gray-300 pt-6 mt-6">
                <h3 className="text-xl font-semibold mb-2">Social Details</h3>
                <p className="text-sm text-gray-600">How can people get in touch with you?</p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Title</label>
                  <input
                    {...register("title")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your title"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Website</label>
                  <input
                    {...register("website")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your website"
                  />
                </div>

                <div className="">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Linkedln URL</label>
                  <input
                    {...register("url")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your Linkedln URL"
                  />
                </div>
              </div>

              {/* Social Details End */}





              {/* License Details */}
              <div className="border-t border-gray-300 pt-6 mt-6">
                <h3 className="text-xl font-semibold mb-2">License Details</h3>
                <p className="text-sm text-gray-600">How can people get in touch with you?</p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Title</label>
                  <input
                    {...register("title")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your title"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Website</label>
                  <input
                    {...register("website")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your website"
                  />
                </div>

                <div className="">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Linkedln URL</label>
                  <input
                    {...register("url")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your Linkedln URL"
                  />
                </div>
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Linkedln URL</label>
                  <input
                    {...register("url")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your Linkedln URL"
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Linkedln URL</label>
                  <input
                    {...register("url")}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    placeholder="Please enter your Linkedln URL"
                  />
                  <div>
                    <label className="block mt-2 text-sm font-medium text-gray-700 mb-2">Linkedln URL</label>
                    <textarea
                      className="sm:p-5 py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                      rows={2}
                      placeholder="Bio...."
                      defaultValue={""}
                    />

                  </div>
                </div>
              </div>

              {/* License Details End */}





              {/* Submit Button */}
              <div className="mt-6">
                <button
                  type="submit"
                  className="bg-indigo-500 text-white px-6 py-2 rounded-md hover:bg-indigo-600"
                >
                  Submit
                </button>
              </div>
            </form>

            {/* Display submitted form data */}
            {formData && (
              <div className="mt-6 bg-gray-100 p-4 rounded-lg">
                <h4 className="text-xl font-semibold mb-4">Submitted Data</h4>
                <pre>{JSON.stringify(formData, null, 2)}</pre>
              </div>
            )}
          </div>
      </div>
    </div>


  </>)
}