import React from 'react'
import { Link } from 'react-router-dom'

export default function Complete_Profile() {
    return (
        <>
            <div className="flex items-center justify-center ">
                <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg text-center">
                    <div className="text-4xl mb-4">😕</div>
                    <h2 className="text-xl font-semibold mb-2">Uh oh! We've noticed you haven't completed your profile.</h2>
                    <p className="text-gray-500 mb-6">
                        Please complete your profile by clicking the button below to enjoy full benefits
                    </p>
                    <Link
                    to={'/admin/profile'}
                     className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out">
                        Complete your profile now!
                    </Link>
                </div>
            </div>
        </>
    )
}
