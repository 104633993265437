
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Sidebar } from "../Sidebar";
import axios from "axios";
import Complete_Profile from "../../Complete Profile/Complete_Profile";

export function Admin_Members() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm();

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
        reset();
    };

    function Submit(data) {
        console.log("Posting data...");

        const formData = new FormData();

        if (data.file && data.file.length > 0) {
            formData.append('file', data.file[0]);
        }

        Object.keys(data).forEach(key => {
            if (key !== 'file') {  // Skip file field since it's already appended
                formData.append(key, data[key]);
            }
        });

        for (let pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        axios.post('https://apna.org.pk/apna.org.pk/api/register', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(() => {
                console.log("Data posted successfully");
                reset(); 
            })
            .catch((error) => {
                console.log(error);
                console.error("Error posting data:", error.response ? error.response.data : error.message);
            });
        closeModal();

    }


    const [Data, setData] = useState([])
    useEffect(() => {
        axios.get('https://apna.org.pk/apna.org.pk/api/user').then((res) => {
            setData(res.data.user)
        }).catch((err) => {
            console.log(err);
        })
    }, [Data])



    const [currentUserId, setCurrentUserId] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // const { register, handleSubmit, reset } = useForm();

    const togglePopup = (userId) => {
        setCurrentUserId(userId);
        setIsPopupVisible(!isPopupVisible);
        reset();
    };

    const onSubmit = async (formData) => {
        const dataToSubmit = {
            ...formData,
            id: currentUserId,
        };

        console.log("dts>>>", dataToSubmit);

        try {
            const response = await axios.post(
                'https://apna.org.pk/apna.org.pk/api/acceptexc',
                dataToSubmit
            );

            console.log('API Response:', response.data);

            setIsPopupVisible(false);
            setSubmitted(true);

            reset();
        }

        catch (error) {
            console.error('Error submitting form:', error.response ? error.response.data : error.message);
            alert('Failed to submit form. Please try again.');
        }
    };

    React.useEffect(() => {
        if (submitted) {
            console.log('Form has been submitted, component will re-render.');
            setSubmitted(false);
        }
    }, [submitted]);





    const [RejectcurrentUserId, setRejectCurrentUserId] = useState(null);
    const [isRejectPopupVisible, setIsRejectPopupVisible] = useState(false);
    const [Rejectsubmitted, setRejectSubmitted] = useState(false);
    const toggleRejectPopup = (userId) => {
        setRejectCurrentUserId(userId);
        setIsRejectPopupVisible(!isPopupVisible);
        reset();
    };

    const onRejectSubmit = async (formData) => {
        const dataToSubmit = {
            ...formData,
            id: RejectcurrentUserId,

        };

        console.log(dataToSubmit);

        try {
            const response = await axios.post(
                'https://apna.org.pk/apna.org.pk/api/rejectexc',
                dataToSubmit
            );

            console.log('API Response:', response.data);

            setIsRejectPopupVisible(false);
            setRejectSubmitted(true);
            reset();
        }

        catch (error) {
            console.error('Error submitting form:', error.response ? error.response.data : error.message);
            alert('Failed to submit form. Please try again.');
        }
    };
    React.useEffect(() => {
        if (submitted) {
            console.log('Form has been submitted, component will re-render.');
            setRejectSubmitted(false);
        }
    }, [Rejectsubmitted]);



    // const [Data, setData] = useState([]);
    const [authorized, setauthorized] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('token');
    const postData = async () => {
        try {
            const response = await axios.post(
                'https://apna.org.pk/apna.org.pk/api/check-token',  
                {
                    first_name: "",
                    email: '',
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                        'Content-Type': 'application/json', 
                    },
                }
            );

            if (response.data.message) {
                setauthorized(response.data.user);
            }
            //   console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error.response);
        }
    };
   
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);  

            await postData();  

            axios.get('https://apna.org.pk/apna.org.pk/api/advisiory')
                .then((res) => {
                    setData(res.data.user);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false); 
                });
        };

        fetchData();
    }, []);



    if (isLoading) {
       
        return (
            <>
                <Sidebar />
                <div className="p-4 sm:ml-64 flex justify-center items-center h-screen">
                    <div role="status">
                        <svg
                            aria-hidden="true"
                            className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
                </div>

            </>
        );
    }   



    return (
        <>
            <Sidebar />
            <div className="p-4 sm:ml-64 m-3">
                {  authorized?.profile !== 'uncomplete' ?
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">

                    <div className="flex flex-wrap justify-end mt-4">
                        <button
                            onClick={openModal}
                            className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition-colors"
                        >
                            Add New Members
                        </button>
                    </div>

                    <section className="container px-4 mx-auto">
                        <div className="flex items-center gap-x-3">
                            <h2 className="text-lg font-medium text-gray-800 dark:text-white">
                                Team members
                            </h2>
                            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">
                                100 users
                            </span>
                        </div>
                        <div className="flex flex-col mt-6">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                            <thead className="bg-gray-50 dark:bg-gray-800">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                        <div className="flex items-center gap-x-3">

                                                            <span>Name</span>
                                                        </div>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                        <button className="flex items-center gap-x-2">
                                                            <span>Status</span>

                                                        </button>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                        <button className="flex items-center gap-x-2">
                                                            <span>Role</span>
                                                        </button>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                        Email address
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                    </th>
                                                    <th scope="col" className="relative py-3.5 px-4">
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>


                                            {Data.map((users) => {
                                                return (<>
                                                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                                        <tr>
                                                            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                                                <div className="inline-flex items-center gap-x-3">

                                                                    <div className="flex items-center gap-x-2">
                                                                        <img
                                                                            className="object-cover w-10 h-10 rounded-full"
                                                                            src={users.file}
                                                                            alt=""
                                                                        />
                                                                        <div>
                                                                            <h2 className="font-medium text-gray-800 dark:text-white ">
                                                                                {users.first_name}
                                                                            </h2>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                                                <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                                                                    <span className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                                                    <h2 className="text-sm font-normal text-emerald-500">
                                                                        Active
                                                                    </h2>
                                                                </div>
                                                            </td>
                                                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                                Design Director
                                                            </td>
                                                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                                {users.email}
                                                            </td>

                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                <div className="flex items-center gap-x-2">

                                                                    {/* <p className="px-3 py-1 text-xs text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">
                                                            Approve
                                                        </p> */}
                                                                    <button
                                                                        className="px-3 py-1 text-xs text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60"
                                                                        onClick={() => togglePopup(users.id)} // Pass the user's ID to the toggle function
                                                                    >
                                                                        Approve
                                                                    </button>
                                                                    <button
                                                                        className="px-3 py-1 text-xs text-pink-500 rounded-full dark:bg-gray-800 bg-pink-100/60"
                                                                        onClick={() => toggleRejectPopup(users.id)}
                                                                    // Example user ID, replace with actual
                                                                    >
                                                                        Reject
                                                                    </button>

                                                                </div>
                                                            </td>
                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                            </td>
                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">

                                                            </td>
                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                <div className="flex items-center gap-x-6">
                                                                    <button className="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            className="w-5 h-5"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                    <button className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            className="w-5 h-5"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>)
                                            })}


                                            {isPopupVisible && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                                    <div className="bg-white p-6 rounded shadow-lg w-1/3">
                                                        <h2 className="text-lg font-bold mb-4">Form</h2>
                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reason">
                                                                    Reason
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="reason"
                                                                    type="text"
                                                                    {...register('reason', { required: true })}
                                                                />
                                                            </div>

                                                            <div className="mb-4">

                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="userID"
                                                                    type="text"
                                                                    value={currentUserId} // Dynamically set the value from state
                                                                    readOnly // Make the field read-only
                                                                    hidden
                                                                />
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    type="text"
                                                                    value={'Exe'}
                                                                    {...register('status')}
                                                                    readOnly
                                                                    hidden
                                                                />
                                                            </div>

                                                            <div className="flex items-center justify-between">
                                                                <button
                                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    type="submit"
                                                                >
                                                                    Submit
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    onClick={() => setIsPopupVisible(false)} // Close the popup
                                                                >
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}


                                            {isRejectPopupVisible && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                                    <div className="bg-white p-6 rounded shadow-lg w-1/3">
                                                        <h2 className="text-lg font-bold mb-4">Form</h2>
                                                        <form onSubmit={handleSubmit(onRejectSubmit)}>
                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reason">
                                                                    Reason
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="reason"
                                                                    type="text"
                                                                    {...register('reason', { required: true })}
                                                                />
                                                            </div>

                                                            <div className="mb-4">

                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="userID"
                                                                    type="text"
                                                                    // value={currentUserId} // Dynamically set the value from state
                                                                    value={RejectcurrentUserId}
                                                                    readOnly // Make the field read-only
                                                                    hidden
                                                                />
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    type="text"
                                                                    value={'reject'}
                                                                    {...register('status')}
                                                                    readOnly
                                                                    hidden
                                                                />
                                                            </div>

                                                            <div className="flex items-center justify-between">
                                                                <button
                                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    type="submit"
                                                                >
                                                                    Submit
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    onClick={() => setIsRejectPopupVisible(false)} // Close the popup
                                                                >
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                            <a
                                href="#"
                                className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 rtl:-scale-x-100"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                                    />
                                </svg>
                                <span>previous</span>
                            </a>
                            <div className="items-center hidden lg:flex gap-x-3">
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-blue-500 rounded-md dark:bg-gray-800 bg-blue-100/60"
                                >
                                    1
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    2
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    3
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    ...
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    12
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    13
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    14
                                </a>
                            </div>
                            <a
                                href="#"
                                className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
                            >
                                <span>Next</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 rtl:-scale-x-100"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                    />
                                </svg>
                            </a>
                        </div>
                    </section>


                    {/* Modal */}
                    {isModalOpen && (
                        <div className="fixed lg:ml-44 lg:mt-16 inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl h-auto max-h-[80vh] overflow-y-auto">
                                <h2 className="text-xl font-bold mb-4">Add New Member</h2>

                                <form onSubmit={handleSubmit(Submit)}>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Profile Photo
                                        </label>
                                        <input
                                            type="file"
                                            {...register("file")}
                                            className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none"
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            {...register("first_name")}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            placeholder="Enter first name"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            {...register("last_name")}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            placeholder="Enter last name"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            {...register("email")}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            {...register("password")}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            placeholder="Enter password"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Phone
                                        </label>
                                        <input
                                            type="tel"
                                            {...register("phone")}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            placeholder="Enter phone number"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Company
                                        </label>
                                        <input
                                            type="text"
                                            {...register("company")}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            placeholder="Enter company name"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Company Type
                                        </label>
                                        <div className="flex items-center mt-1">
                                            <label className="mr-4">
                                                <input
                                                    type="radio"
                                                    value="Private"
                                                    {...register("type")}
                                                    className="mr-2"
                                                />
                                                Private
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="Public"
                                                    {...register("type")}
                                                    className="mr-2"
                                                />
                                                Public
                                            </label>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Designation
                                        </label>
                                        <input
                                            type="text"
                                            {...register("bio")}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            placeholder="Enter designation"
                                        />
                                    </div>

                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            className="bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2 hover:bg-gray-600 transition-colors"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition-colors"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? "Submitting..." : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}



                </div>
                :
                <Complete_Profile />
                } 
            </div>
        </>
    );
}