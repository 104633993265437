import React from 'react'

export function C() {
    return (<>
        <div className="bg-[#f5f5f5]  col-start-1 col-end-7 ...">
            <>
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                        <div className="flex flex-col items-center justify-center text-center p-4 bg-opacity-50  col-span-full">   
                            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-blue-600">
                                Digital Community
                            </h1>
                            <p className="mt-2 text-sm text-gray-600 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 2xl:px-16 text-base lg:text-lg text-[#414dd7]">
                                A dynamic online community of ISPs, Network services, and equipment providers to prosper through ideas, collaborations, and connections.
                            </p>
                        </div>
                        <div
                            className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 border shadow-sm rounded-xl dark:bg-neutral-900 border-[#3bc3ef]"
                            style={{
                                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                                border: '1px solid #3bc3ef' // Set initial border style
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                                e.currentTarget.style.border = 'none';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                                e.currentTarget.style.border = '1px solid #3bc3ef';
                            }}
                        >
                            <div className="inline-flex justify-center items-center">
                                <span className="size-2 inline-block bg-[#3bc3ef] rounded-full me-2" />
                                <span className="text-xs font-semibold uppercase text-[#414dd7] dark:text-neutral-400">
                                    Member
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="flex justify-center">
                                    <img src="/media/section-c/team.png" alt="" className="w-20 text-center" />
                                </div>
                            </div>
                            <dl className="flex justify-center items-center divide-x divide-pink-600 dark:divide-neutral-800">
                                <dt className="pe-3">
                                    <span className="text-[#414dd7]">
                                        <svg
                                            className="inline-block size-4 self-center"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                                            />
                                        </svg>
                                        <span className="inline-block text-sm">5.6%</span>
                                    </span>
                                    <span className="block text-sm text-[#414dd7] dark:text-neutral-500">
                                        change
                                    </span>
                                </dt>
                                <dd className="text-start ps-3">
                                    <span className="text-sm font-semibold text-[#414dd7] dark:text-neutral-200">
                                        7
                                    </span>
                                    <span className="block text-sm text-[#414dd7] dark:text-neutral-500">
                                        last week
                                    </span>
                                </dd>
                            </dl>
                        </div>
                        <div
                            className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 border shadow-sm rounded-xl dark:bg-neutral-900 border-[#3bc3ef]"
                            style={{
                                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                                border: '1px solid #3bc3ef' // Set initial border style
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                                e.currentTarget.style.border = 'none';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                                e.currentTarget.style.border = '1px solid #3bc3ef';
                            }}
                        >
                            <div className="inline-flex justify-center items-center">
                                <span className="size-2 inline-block bg-[#3bc3ef] rounded-full me-2" />
                                <span className="text-xs font-semibold uppercase text-[#414dd7] dark:text-neutral-400">
                                    Complaints Resolved
                                </span>
                            </div>
                            <div className="flex justify-center">
                                <img src="/media/section-c/complaint.png" alt="" className="w-20 text-center" />
                            </div>
                            <dl className="flex justify-center items-center divide-x divide-pink-600 dark:divide-neutral-800">
                                <dt className="pe-3">
                                    <span className="text-[#414dd7]">
                                        <svg
                                            className="inline-block size-4 self-center"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                                            />
                                        </svg>
                                        <span className="inline-block text-sm">1.7%</span>
                                    </span>
                                    <span className="block text-sm text-[#414dd7] dark:text-neutral-500">
                                        change
                                    </span>
                                </dt>
                                <dd className="text-start ps-3">
                                    <span className="text-sm font-semibold text-[#414dd7] dark:text-neutral-200">
                                        5
                                    </span>
                                    <span className="block text-sm text-[#414dd7] dark:text-neutral-500">
                                        last week
                                    </span>
                                </dd>
                            </dl>
                        </div>
                        <div
                            className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 border shadow-sm rounded-xl dark:bg-neutral-900 border-[#3bc3ef]"
                            style={{
                                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                                border: '1px solid #3bc3ef' // Set initial border style
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                                e.currentTarget.style.border = 'none';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                                e.currentTarget.style.border = '1px solid #3bc3ef';
                            }}
                        >
                            <div className="inline-flex justify-center items-center">
                                <span className="size-2 inline-block bg-[#3bc3ef] rounded-full me-2" />
                                <span className="text-xs font-semibold uppercase text-[#414dd7] dark:text-neutral-400">
                                    Licenses
                                </span>
                            </div>
                            <div className="flex justify-center">
                                <img src="/media/section-c/licensing.png" alt="" className="w-20 text-center" />
                            </div>
                            <dl className="flex justify-center items-center divide-x divide-pink-600 dark:divide-neutral-800">
                                <dt className="pe-3">
                                    <span className="text-[#414dd7]">
                                        <svg
                                            className="inline-block size-4 self-center"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                                            />
                                        </svg>
                                        <span className="inline-block text-sm text-[#414dd7]">5.6%</span>
                                    </span>
                                    <span className="block text-sm text-[#414dd7] dark:text-neutral-500">
                                        change
                                    </span>
                                </dt>
                                <dd className="text-start ps-3">
                                    <span className="text-sm font-semibold text-[#414dd7] dark:text-neutral-200">
                                        7
                                    </span>
                                    <span className="block text-sm text-[#414dd7] dark:text-neutral-500">
                                        last week
                                    </span>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </>
        </div>
    </>
    )
}






