import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export function Login() {

  const { register, handleSubmit } = useForm()
  // const [Data, setData] = useState([{}])
  // console.log(Data);

  // useEffect(() => {
  //   axios.get('https://apna.org.pk/apna.org.pk/api/user').then((res) => {
  //     const users = res.data.user;
  //     setData(users)
  //   }).catch((err) => {
  //     console.log(err);
  //   })
  // }, [])


  // let Status = Data.map((items) => ({
  //   LoginEmail: items.email,
  //   Status: items.status
  // }))

  // const [signIn, setSignIn] = useState({});

  // useEffect(() => {
  //   if (signIn.email && signIn.password && Data.length > 0) {
  //     const user = Data.find(user => user.email === signIn.email);
  //     if (user) {
  //       if (user.password === signIn.password) { // Check if password matches
  //         if (user.status === 'pending') {
  //           console.log('Abhi tum pending mein ho'); // Message for pending status
  //         } else if (user.status === 'rejected') {
  //           console.log('Tum nahi ja sakte next page mein'); // Message for rejected status
  //         } else {
  //           console.log('Tum next page mein ja sakte ho'); // Message for other statuses
  //           // Optionally navigate here if using history
  //           // history.push('/new-page');
  //         }
  //       } else {
  //         console.log('Error: Password does not match'); // Password does not match
  //       }
  //     } else {
  //       console.log('Error: Email does not match any user'); // Email does not match
  //     }
  //   }
  // }, [signIn, Data]);


  const Navigate = useNavigate()

  const Submit = (data) => {
    
    console.log(data);
    axios.post('https://apna.org.pk/apna.org.pk/api/login', data).then((res) => {
      // console.log(res.data.access_token);
      const token = res.data.access_token
      const first_name = data
      if (token) {
        localStorage.setItem('token', token);
        Navigate('/admin')
      } else {
        alert('Invalid login. Please check your credentials.');
      }
    }).catch((err) => {
      console.log(err);
    })
  };

  return (
    <>
      <section className="bg-[#f5f5f5] dark:bg-gray-900 ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link to={'/'} >
            <img
              src="/media/logo/logo.png"
              className="mr-3 h-10 w-auto sm:h-12 md:h-16 lg:h-20 xl:h-16"
              alt="Logo"
            />
          </Link>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-blue-600 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <form className="space-y-4 md:space-y-6" action="#" onSubmit={handleSubmit(Submit)}>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium  dark:text-white text-blue-600"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                    {...register('email')}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-blue-600 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    {...register('password')}
                  />
                </div>

                <button
                  type="submit"
                  className="w-full text-white bg-primary-600 hover:bg-pink-700 focus:ring-4 focus:outline-none focus:ring-pink-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-pink-700 dark:focus:ring-pink-800 bg-pink-600 "
                >
                  Login
                </button>
                <p className="text-sm font-light text-gray-800 dark:text-gray-800">
                  Don’t have an account yet?{" "}
                  <Link to={'/register'} >
                    <a
                      href="#"
                      className="font-medium text-primary-600 hover:underline dark:text-primary-500 "
                    >
                      Sign up
                    </a>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}