import React from 'react'
import { Sidebar } from '../Sidebar'
import { useState } from 'react';

export function Mangement() {

    const options = [
        { name: 'John Doe', email: 'john.doe@example.com' },
        { name: 'Jane Smith', email: 'jane.smith@example.com' },
    ]

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };



    return (<>
        <Sidebar />
        <div className="p-4 sm:ml-64">
            <div className="relative p-4 mt-20">
                <>

                    <div className="relative w-64">
                        <button
                            onClick={toggleDropdown}
                            className="w-full flex justify-between items-center p-2 border border-gray-300 bg-white rounded-md"
                        >
                            {selectedOption ? `${selectedOption.name} (${selectedOption.email})` : 'Select an option'}
                            {/* <span className="material-icons">arrow_drop_down</span> */}
                        </button>
                        {isOpen && (
                            <div className="absolute left-0 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
                                {options.map((option, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSelect(option)}
                                        className="p-2 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <div className="font-semibold">{option.name}</div>
                                        <div className="text-sm text-gray-600">{option.email}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </>

            </div>
        </div>

    </>
    )
}
