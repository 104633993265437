import React, { useEffect } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function Register() {

  const Navigate = useNavigate()

  const { register, handleSubmit, reset } = useForm();

  
  function Submit(data) {
    console.log("Posting data...", data);
    let first_name = data.first_name

    // axios.post('https://apna.org.pk/apna.org.pk/api/register', data)
    axios.post('https://apna.org.pk/apna.org.pk/api/signup', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        console.log("Data posted successfully", res.data);
        console.log("Message: ", res.data.message);
        console.log("User Token: ", res.data.access_token);
        if (res.data.access_token) {
          localStorage.setItem("token", res.data.access_token)
          localStorage.setItem('first_name', first_name)
          Navigate('/admin')
          reset();

        }
      })
      .catch((error) => {
        console.log(error);
        console.error("Error posting data:", error.response ? error.response.data : error.message);
      });

  }

  const [type, setType] = useState("");
  const handleRadioChange = e => {
    setType(e.target.value);
  };

  return (
    // <>
    //     <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 mx-auto mt-10">
    //         <div className="rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-800 bg-[#f5f5f5]">
    //             <div className="mb-8">
    //                 <h2 className="text-xl font-bold text-blue-600 dark:text-neutral-200">
    //                     Membership Application Form
    //                 </h2>

    //             </div>
    //             <form onSubmit={handleSubmit(Submit)}>
    //                 <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

    //                     <div className="sm:col-span-3">
    //                         <label className="inline-block text-sm text-gray-800 mt-2.5">
    //                             Profile photo
    //                         </label>
    //                     </div>
    //                     <div className="sm:col-span-9">
    //                         <div className="flex items-center gap-5">
    //                             <img
    //                                 className="inline-block size-16 rounded-full ring-2 ring-white dark:ring-neutral-900"
    //                                 src="https://preline.co/assets/img/160x160/img1.jpg"
    //                                 alt="Avatar"
    //                             />
    //                             <div className="flex gap-x-2">
    //                                 <div>
    //                                     <input
    //                                         type="file"
    //                                         className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //                                         accept=".jpeg, .png, .jpg, .gif, .svg"
    //                                         {...register("file")}
    //                                     />
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="sm:col-span-3 text-blue-600">
    //                         <label
    //                             htmlFor="af-account-full-name"
    //                             className="inline-block text-sm text-blue-6000 mt-2.5 dark:text-neutral-200"
    //                         >
    //                             Full name
    //                         </label>
    //                     </div>
    //                     <div className="sm:col-span-9">
    //                         <div className="sm:flex">
    //                             <input
    //                                 id="af-account-full-name"
    //                                 name='first_name'
    //                                 type="text"
    //                                 className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                 placeholder="First Name"
    //                                 required
    //                                 {...register('first_name')}
    //                             />
    //                             <input
    //                                 type="text"
    //                                 name='last_name'
    //                                 className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                 // placeholder="Last Name"
    //                                 placeholder='Last Name'
    //                                 required
    //                                 {...register('last_name')}
    //                             />
    //                         </div>
    //                     </div>

    //                     <div className="sm:col-span-3">
    //                         <label
    //                             htmlFor="af-account-email"
    //                             className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
    //                         >
    //                             Email
    //                         </label>
    //                     </div>
    //                     <div className="sm:col-span-9">
    //                         <input
    //                             id="af-account-email"
    //                             name='email'
    //                             type="email"
    //                             className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                             placeholder="Email@gmail.com"
    //                             required
    //                             {...register('email')}

    //                         />
    //                     </div>

    //                     <div className="sm:col-span-3">
    //                         <label
    //                             htmlFor="af-account-password"
    //                             className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
    //                         >
    //                             Password
    //                         </label>
    //                     </div>
    //                     <div className="sm:col-span-9">
    //                         <div className="space-y-2">
    //                             <input
    //                                 id="af-account-password"
    //                                 name='password'
    //                                 type="password"
    //                                 className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                 placeholder="Password"
    //                                 required
    //                                 {...register('password')}

    //                             />
    //                         </div>
    //                     </div>

    //                     <div className="sm:col-span-3">
    //                         <label
    //                             htmlFor="af-account-phone"
    //                             className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
    //                         >
    //                             Phone
    //                         </label>

    //                     </div>
    //                     <div className="sm:col-span-9">
    //                         <div className="sm:flex">
    //                             <input
    //                                 id="af-account-phone"
    //                                 name='phone'
    //                                 type="text"
    //                                 className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                 placeholder="+92300-1510222"
    //                                 required
    //                                 {...register('phone')}
    //                             />
    //                             <select className="py-2 px-3 pe-9 block w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600">
    //                                 <option>Mobile</option>
    //                                 <option>Home</option>
    //                                 <option>Work</option>
    //                                 <option>Fax</option>
    //                             </select>
    //                         </div>
    //                     </div>


    //                     <div className="sm:col-span-3">
    //                         <label
    //                             htmlFor="af-account-password"
    //                             className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
    //                         >
    //                             Business Type
    //                         </label>
    //                     </div>
    //                     <div className="sm:col-span-9">
    //                         <div className="space-y-2">
    //                             <input
    //                                 id=""
    //                                 name='buisness'
    //                                 type="text"
    //                                 className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                 placeholder="Business type"
    //                                 required
    //                                 {...register('business')}

    //                             />
    //                         </div>
    //                     </div>
    //                     <div className="sm:col-span-3">
    //                         <label
    //                             htmlFor="af-account-company"
    //                             className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
    //                         >
    //                             Type
    //                         </label>
    //                     </div>

    //                     <div className="sm:col-span-9">
    //                         <div className="flex items-center">
    //                             <label className="inline-flex items-center mr-4">
    //                                 <input
    //                                     type="radio"
    //                                     name="types"
    //                                     value="company"
    //                                     className="form-radio text-blue-600 focus:border-blue-500 focus:ring-blue-500 dark:focus:ring-blue-600"
    //                                     checked={type === 'company'}
    //                                     {...register('type')}
    //                                     onChange={handleRadioChange}
    //                                 />
    //                                 <span className="ml-2 text-sm text-gray-800 dark:text-neutral-400">Company</span>
    //                             </label>

    //                             <label className="inline-flex items-center">
    //                                 <input
    //                                     type="radio"
    //                                     name="types"
    //                                     value="franchise"
    //                                     className="form-radio text-blue-600 focus:border-blue-500 focus:ring-blue-500 dark:focus:ring-blue-600"
    //                                     checked={type === 'franchise'}
    //                                     {...register('type')}
    //                                     onChange={handleRadioChange}
    //                                 />
    //                                 <span className="ml-2 text-sm text-gray-800 dark:text-neutral-400">Franchise</span>
    //                             </label>
    //                         </div>
    //                     </div>

    //                     {(type === "franchise" || type === "company") && <div className={`sm:col-span-3 text-blue-600 }`}>
    //                         <label
    //                             htmlFor="af-account-full-name"
    //                             className="inline-block text-sm text-blue-6000 mt-2.5 dark:text-neutral-200"
    //                         >
    //                             {type === "company" ? "Company" : "Franchise"}
    //                         </label>
    //                     </div>}

    //                     {type === 'company' && (
    //                         <div className="sm:col-span-9">
    //                             <div className="sm:flex">
    //                                 <input
    //                                     type="text"
    //                                     id="company-name"
    //                                     name="companyName"
    //                                     className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                     placeholder='Company'
    //                                     {...register('company')}
    //                                 />
    //                                 <input
    //                                     type="text"
    //                                     id="ntn"
    //                                     name="ntn"
    //                                     className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                     placeholder='NTN'
    //                                     {...register('ntn')}
    //                                 />
    //                             </div>
    //                         </div>



    //                     )}
    //                     {type === 'franchise' && (
    //                         <div className="sm:col-span-9">
    //                             <div className="sm:flex">

    //                                 <input
    //                                     type="text"
    //                                     id="company-name"
    //                                     name="companyName"
    //                                     className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                     required
    //                                     placeholder='Franchise'
    //                                     {...register('company')}
    //                                 />
    //                                 <input
    //                                     type="text"
    //                                     id="ntn"
    //                                     name="ntn"
    //                                     className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                     placeholder='NTN'
    //                                     {...register('ntn')}
    //                                 />
    //                             </div>
    //                         </div>
    //                     )}

    //                     {((type === "franchise") || (type === "company")) && <div className="sm:col-span-3 text-blue-600 ">
    //                         <label
    //                             htmlFor="af-account-full-name"
    //                             className="inline-block text-sm text-blue-6000 mt-2.5 dark:text-neutral-200"
    //                         >
    //                             Designation
    //                         </label>
    //                     </div>}
    //                     {(type === "franchise" || type === "company") && (
    //                         <>
    //                             <div className="sm:col-span-9">
    //                                 <div className="sm:flex">
    //                                     <input
    //                                         id="af-account-full-name"
    //                                         name='first_name'
    //                                         type="text"
    //                                         className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                         placeholder="Designation"
    //                                         required
    //                                         {...register('bio')}
    //                                     />
    //                                     <input
    //                                         type="text"
    //                                         name='last_name'
    //                                         className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    //                                         placeholder="CNIC"
    //                                         required
    //                                         {...register('cnic')}
    //                                     />
    //                                 </div>
    //                             </div>
    //                         </>
    //                     )}
    //                 </div>
    //                 <div className="mt-5 flex justify-end gap-x-2">
    //                     <button
    //                         type="submit"
    //                         className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
    //                     >
    //                         Register
    //                     </button>
    //                 </div>
    //             </form>
    //         </div>
    //     </div>
    // </>

    <>
      {/* Hero */}
      <div className="relative bg-gradient-to-bl from-blue-100 via-transparent dark:from-blue-950 dark:via-transparent">
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          {/* Grid */}
          <div className="grid items-center md:grid-cols-2 gap-8 lg:gap-12">
            <div>
              <p className="inline-block text-sm font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent dark:from-blue-400 dark:to-violet-400">
                Preline: A vision for 2024
              </p>
              {/* Title */}
              <div className="mt-4 md:mb-12 max-w-2xl">
                <h1 className="mb-4 font-semibold text-gray-800 text-4xl lg:text-5xl dark:text-neutral-200">
                  Fully customizable rules to match your unique needs
                </h1>
                <p className="text-gray-600 dark:text-neutral-400">
                  We provide you with a test account that can be set up in seconds.
                  Our main focus is getting responses to you as soon as we can.
                </p>
              </div>
              {/* End Title */}
              {/* Blockquote */}
              <blockquote className="hidden md:block relative max-w-sm">
                <svg
                  className="absolute top-0 start-0 transform -translate-x-6 -translate-y-8 size-16 text-gray-200 dark:text-neutral-800"
                  width={16}
                  height={16}
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                    fill="currentColor"
                  />
                </svg>
                <div className="relative z-10">
                  <p className="text-xl italic text-gray-800 dark:text-white">
                    Amazing people to work with. Very fast and professional partner.
                  </p>
                </div>

              </blockquote>
              {/* End Blockquote */}
            </div>
            {/* End Col */}
            <div>
              {/* Form */}
              <form onSubmit={handleSubmit(Submit)}>
                <div className="lg:max-w-lg lg:mx-auto lg:me-0 ms-auto">
                  {/* Card */}
                  <div className="p-4 sm:p-7 flex flex-col bg-white rounded-2xl shadow-lg dark:bg-neutral-900">
                    <div className="text-center">
                      <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                        Start your free trial
                      </h1>
                      <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">
                        Already have an account?
                        <a
                          className="text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500"
                          href="#"
                        >
                          Sign in here
                        </a>
                      </p>
                    </div>
                    <div className="mt-5">
                      {/* Grid */}
                      <div className="grid grid-cols-2 gap-4">
                        {/* Input Group */}
                        <div>
                          {/* Floating Input */}
                          <div className="relative">
                            <input
                              type="text"
                              id="hs-hero-signup-form-floating-input-first-name"
                              className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2"
                              placeholder="John"
                              {...register('first_name')}
                              required
                            />
                            <label
                              htmlFor="hs-hero-signup-form-floating-input-first-name"
                              className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                  peer-focus:scale-90
                  peer-focus:translate-x-0.5
                  peer-focus:-translate-y-1.5
                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                  peer-[:not(:placeholder-shown)]:scale-90
                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                            >
                              First name
                            </label>
                          </div>
                        </div>

                        <div>
                          <div className="relative">
                            <input
                              type="text"
                              id="hs-hero-signup-form-floating-input-last-name"
                              className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2"
                              placeholder="Doe"
                              {...register('last_name')}
                              required

                            />
                            <label
                              htmlFor="hs-hero-signup-form-floating-input-last-name"
                              className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                  peer-focus:scale-90
                  peer-focus:translate-x-0.5
                  peer-focus:-translate-y-1.5
                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                  peer-[:not(:placeholder-shown)]:scale-90
                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                            >
                              Last name
                            </label>
                          </div>
                          {/* End Floating Input */}
                        </div>
                        {/* End Input Group */}
                        {/* Input Group */}
                        <div>
                          {/* Floating Input */}
                          <div className="relative">
                            <input
                              type="email"
                              id="hs-hero-signup-form-floating-input-email"
                              className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2"
                              placeholder="you@email.com"
                              {...register('email')}
                              required

                            />
                            <label
                              htmlFor="hs-hero-signup-form-floating-input-email"
                              className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                  peer-focus:scale-90
                  peer-focus:translate-x-0.5
                  peer-focus:-translate-y-1.5
                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                  peer-[:not(:placeholder-shown)]:scale-90
                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                            >
                              Email
                            </label>
                          </div>
                          {/* End Floating Input */}
                        </div>
                        {/* End Input Group */}
                        {/* Input Group */}
                        <div>
                          {/* Floating Input */}
                          <div className="relative">
                            <input
                              type="text"
                              id="hs-hero-signup-form-floating-input-company-name"
                              className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2"
                              placeholder="Preline"
                              {...register('phone')}
                              required

                            />
                            <label
                              htmlFor="hs-hero-signup-form-floating-input-company-name"
                              className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                  peer-focus:scale-90
                  peer-focus:translate-x-0.5
                  peer-focus:-translate-y-1.5
                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                  peer-[:not(:placeholder-shown)]:scale-90
                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                            >
                              Contact number
                            </label>
                          </div>
                          {/* End Floating Input */}
                        </div>
                        {/* End Input Group */}
                        {/* Input Group */}
                        <div className="relative col-span-full">
                          {/* Floating Input */}
                          <div className="relative">
                            <input
                              type="password"
                              id="hs-hero-signup-form-floating-input-new-password"
                              className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2"
                              placeholder="********"
                              {...register('password')}
                              required

                            />
                            <label
                              htmlFor="hs-hero-signup-form-floating-input-new-password"
                              className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                  peer-focus:scale-90
                  peer-focus:translate-x-0.5
                  peer-focus:-translate-y-1.5
                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                  peer-[:not(:placeholder-shown)]:scale-90
                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                            >
                              Password
                            </label>
                          </div>
                          {/* End Floating Input */}
                          <div
                            id="hs-strong-password-popover"
                            className="hidden absolute z-10 w-full bg-gray-100 rounded-lg p-4 dark:bg-neutral-950"
                          >
                            <div
                              id="hs-strong-password-in-popover"
                              data-hs-strong-password='{
                    "target": "#hs-hero-signup-form-floating-input-new-password",
                    "hints": "#hs-strong-password-popover",
                    "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-teal-500 h-2 flex-auto rounded-full bg-blue-500 opacity-50 mx-1",
                    "mode": "popover"
                  }'
                              className="flex mt-2 -mx-1"
                            ></div>
                            <h4 className="mt-3 text-sm font-semibold text-gray-800 dark:text-white">
                              Your password must contain:
                            </h4>
                            <ul className="space-y-1 text-sm text-gray-500 dark:text-neutral-500">
                              <li
                                data-hs-strong-password-hints-rule-text="min-length"
                                className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                              >
                                <span className="hidden" data-check="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <polyline points="20 6 9 17 4 12" />
                                  </svg>
                                </span>
                                <span data-uncheck="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M18 6 6 18" />
                                    <path d="m6 6 12 12" />
                                  </svg>
                                </span>
                                Minimum number of characters is 6.
                              </li>
                              <li
                                data-hs-strong-password-hints-rule-text="lowercase"
                                className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                              >
                                <span className="hidden" data-check="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <polyline points="20 6 9 17 4 12" />
                                  </svg>
                                </span>
                                <span data-uncheck="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M18 6 6 18" />
                                    <path d="m6 6 12 12" />
                                  </svg>
                                </span>
                                Should contain lowercase.
                              </li>
                              <li
                                data-hs-strong-password-hints-rule-text="uppercase"
                                className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                              >
                                <span className="hidden" data-check="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <polyline points="20 6 9 17 4 12" />
                                  </svg>
                                </span>
                                <span data-uncheck="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M18 6 6 18" />
                                    <path d="m6 6 12 12" />
                                  </svg>
                                </span>
                                Should contain uppercase.
                              </li>
                              <li
                                data-hs-strong-password-hints-rule-text="numbers"
                                className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                              >
                                <span className="hidden" data-check="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <polyline points="20 6 9 17 4 12" />
                                  </svg>
                                </span>
                                <span data-uncheck="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M18 6 6 18" />
                                    <path d="m6 6 12 12" />
                                  </svg>
                                </span>
                                Should contain numbers.
                              </li>
                              <li
                                data-hs-strong-password-hints-rule-text="special-characters"
                                className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                              >
                                <span className="hidden" data-check="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <polyline points="20 6 9 17 4 12" />
                                  </svg>
                                </span>
                                <span data-uncheck="">
                                  <svg
                                    className="shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M18 6 6 18" />
                                    <path d="m6 6 12 12" />
                                  </svg>
                                </span>
                                Should contain special characters.
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Input Group */}
                        {/* Input Group */}

                      </div>
                      {/* End Grid */}

                      <div className="mt-5">
                        <button
                          type="submit"
                          className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Card */}
                </div>
              </form>
              {/* End Form */}
            </div>
            {/* End Col */}
          </div>
          {/* End Grid */}

        </div>
        {/* End Clients Section */}
      </div>
      {/* End Hero */}
    </>


  );
}