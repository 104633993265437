// import React from 'react'
// import './meni.css'
// export function Menifesto() {
//     return (<>
//   <>


//  <>

//   {/* <section className="absolute top-0 w-full h-screen section-background">
//     <div className="absolute inset-0  sm:bg-transparent s ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l" />
//     <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
//       <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
//         <h1 classname="text-3xl font-extrabold text-white sm:text-5xl">
//           Let us find your
//           <strong className="block font-extrabold text-rose-500">
//             {" "}
//             Forever Home.{" "}
//           </strong>
//         </h1>
//         <p className="mt-4 max-w-lg text-white sm:text-xl/relaxed">
//           Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt
//           illo tenetur fuga ducimus numquam ea!
//         </p>
//         <div className="mt-8 flex flex-wrap gap-4 text-center">
//           <a
//             href="#"
//             className="block w-full rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
//           >
//             Get Started
//           </a>
//           <a
//             href="#"
//             className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
//           >
//             Learn More
//           </a>
//         </div>
//       </div>
//     </div>
//   </section> */}
// </>

// </> 



//     </>
//     )
// }








import React, { useState } from 'react';
import { Link } from 'react-router-dom';
export function Menifesto() {
  const [isOpen, setIsOpen] = useState(false);



  return (
    <>
      <section className=" bg-[#f5f5f5]">

        <div className="container px-6 py-16 mx-auto text-center">
          <div className="max-w-lg mx-auto">

            <h1 className="text-3xl font-semibold text-blue-600 dark:text-white lg:text-4xl">
              Manifesto
            </h1>
            <p className="mt-6 text-gray-800 dark:text-gray-300">
              The All Pakistan Network Association (APNA) members stand united to safeguard the rights and interests of all companies and operators in this sector. Regardless of religion, nationality, language, or profession, we pledge to work collectively to create a secure and thriving environment. Our commitment to unity, inclusivity, and progress is determined as we navigate the challenges and opportunities that define our industry.
            </p>

            <div className="text-center mt-5">
              <Link to={'/register'} >
                <a
                  href="#"
                  className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
                >
                  Become a Member
                </a>
              </Link>
            </div>
          </div>

          <div className="flex justify-center mt-10">
            <img
              className="object-cover w-full h-96 rounded-xl lg:w-4/5"
              src="https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"
              alt="Illustration"
            />
          </div>
        </div>
      </section>









      <div className="container bg-[#f5f5f5] text-[#414dd7] max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto sm:mt-24">
        <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-6">
          <div className="lg:col-span-2">
            <div className="py-8 lg:pe-8">
              <div className="space-y-5 lg:space-y-8">
                <h2 className="text-3xl font-bold lg:text-5xl text-blue-600">
                  All Pakistan Network Association
                </h2>
                <div className="flex items-center gap-x-5">
                  <h2 className="text-3xl font-bold lg:text-2xl text-blue-600">
                    Objective
                  </h2>
                  <p className="text-xs sm:text-sm text-gray-800 dark:text-neutral-200">
                    January 18, 2023
                  </p>
                </div>
                <h2 className="text-1xl font-bold lg:text-1xl text-blue-600">
                  1. Unity and Inclusivity
                </h2>
                <p className="text-lg text-gray-800 dark:text-neutral-200">
                  <span className='text-blue-600'>Unified Platform:</span>
                  <p className='text-gray-800'>
                    Bring together all ISPs, network service providers, network services, and operators on a unified platform, promoting unity irrespective of religion, nationality, language, or profession. By consolidating our efforts, we aim to build a stronger, more cohesive industry.
                  </p>
                </p>
                <p className="text-lg text-gray-800 dark:text-neutral-200">
                  Promoting Cooperation:
                  Encourage inclusivity and cooperation among members to achieve common goals and strengthen our collective voice. By embracing diversity and working together, we can better address the challenges we face and capitalize on opportunities.
                </p>
                <div className="text-center">
                  <div className="grid lg:grid-cols-2 gap-3">
                    <div className="grid grid-cols-2 lg:grid-cols-1 gap-3">
                      <figure className="relative w-full h-60">
                        <img
                          className="size-full absolute top-0 start-0 object-cover rounded-xl"
                          src="https://images.unsplash.com/photo-1670272505340-d906d8d77d03?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                          alt="Image Description"
                        />
                      </figure>
                      <figure className="relative w-full h-60">
                        <img
                          className="size-full absolute top-0 start-0 object-cover rounded-xl"
                          src="https://images.unsplash.com/photo-1671726203638-83742a2721a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                          alt="Image Description"
                        />
                      </figure>
                    </div>
                    <figure className="relative w-full h-72 sm:h-96 lg:h-full">
                      <img
                        className="size-full absolute top-0 start-0 object-cover rounded-xl"
                        src="https://images.unsplash.com/photo-1671726203394-491c8b574a0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=988&q=80"

                        alt="Image Description"
                      />
                    </figure>
                  </div>
                  <span className="mt-3 block text-sm text-center text-gray-800 dark:text-neutral-500">
                    Working process
                  </span>
                </div>
                <h2 className="text-1xl font-bold lg:text-1xl text-blue-600">
                  2. Business-Friendly Environment
                </h2>
                <p className="text-lg text-gray-800 dark:text-neutral-200">
                  Preventing Disruptions:
                  Advocate for a business-friendly environment by implementing measures to prevent cable theft, cable cuts, and other disruptions to ensure continuous and reliable network services.
                </p>
                <p className="text-lg text-gray-800 dark:text-neutral-200">
                  Infrastructure Protection:
                  Collaborate with authorities to ensure the protection of critical infrastructure for our operations. {" "}
                  <a
                    className="text-pink-600 decoration-2 hover:underline font-medium dark:text-pink-600"
                    href="#"
                  >
                    Securing our network infrastructure supports the growth and stability of network services.
                  </a>
                </p>
                <blockquote className="text-center p-4 sm:px-7">
                  <p className="text-xl font-medium text-blue-600 lg:text-2xl lg:leading-normal xl:text-2xl xl:leading-normal dark:text-blue-600">
                    To say that switching to Preline has been life-changing is an understatement. My business has tripled and I got my life back.
                  </p>
                  <p className="mt-5 text-blue-600 dark:text-blue-600">
                    Nicole Grazioso
                  </p>
                </blockquote>
                <figure>
                  <img
                    className="w-full object-cover rounded-xl"
                    // src="https://images.unsplash.com/photo-1671726203454-488ab18f7eda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                    src='https://images.unsplash.com/photo-1602526430780-782d6b1783fa?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    alt="Image Description"
                  />
                </figure>
                <div className="space-y-3">
                  <h3 className="text-2xl font-semibold text-blue-600">
                    3. Economic Stability and Justice
                  </h3>
                  <p className="text-lg text-gray-800 dark:text-gray-800">
                    Addressing Economic Challenges:
                    Address the challenges posed by a struggling economy and violence by advocating for systemic changes that promote stability and fairness. We believe that a stable economic environment is essential for the growth and prosperity of our industry.
                  </p>
                  <p className="text-lg text-gray-800 dark:text-gray-800">
                    Immediate Justice:
                    Strive for immediate justice and resolution of conflicts affecting our members to ensure a stable operating environment for all network service providers. Our commitment to fairness and justice is paramount in all our endeavors.
                  </p>
                </div>
                <div className="space-y-3">
                  <h3 className="text-2xl font-semibold text-blue-600">
                    4. Community Building and Support
                  </h3>
                  <p className="text-lg text-gray-800 dark:text-gray-800">
                    Regular Engagement:
                    Encourage a sense of community among members through regular meetings, workshops, and collaborative projects. By encouraging regular interaction, we strengthen our network and promote knowledge sharing.
                  </p>
                  <p className="text-lg text-gray-800 dark:text-gray-800">
                    Support Network:
                    Create a support network for sharing best practices and resources to enhance operational efficiency and security for all network service providers. Collaboration and mutual support are key to our collective success.
                  </p>
                </div>
                <div className="space-y-3">
                  <h3 className="text-2xl font-semibold text-blue-600">
                    5. Market Development and Fair Competition
                  </h3>
                  <p className="text-lg text-gray-800 dark:text-gray-800">
                    Ethical Standards:
                    Promote fair competition by setting ethical standards and practices for all members. Our commitment to ethics ensures a level playing field and creates trust within the industry.
                  </p>
                  <p className="text-lg text-gray-800 dark:text-gray-800">
                    Business Opportunities:
                    Organize trade fairs, seminars, and networking events to facilitate business opportunities and partnerships among ISPs, network service providers, and network equipment providers. These events provide platforms for collaboration and growth.
                  </p>
                </div>
              </div>
            </div>
          </div>

          
          <div className="lg:col-span-1 lg:w-full lg:h-full lg:bg-gradient-to-r lg:from-gray-50 lg:via-transparent lg:to-transparent dark:from-neutral-800">
            <div className="sticky top-0 start-0 py-8 lg:ps-8">
              <div className="p-4">
                <h2 className="text-2xl font-bold mb-4 text-blue-600">Action Plan</h2>
                <h3 className="text-xl font-semibold mb-2 text-blue-600">1. Advocacy and Lobbying</h3>
                <p className="mb-4 text-gray-800"><strong>Policy Influence:</strong> Engage with government bodies and regulatory authorities to influence policies that benefit our members. Our advocacy efforts are focused on creating a favorable regulatory environment for our industry.</p>
                <p className="mb-4 text-gray-800"><strong>Stringent Laws:</strong> Advocate for stringent laws and regulations to deter cable theft, vandalism, and other disruptions to network services and infrastructure. By pushing for stronger legal frameworks, we aim to protect our assets and ensure service continuity.</p>
                <h3 className="text-xl font-semibold mb-2 text-blue-600">2. Collaborative Security Measures</h3>
                <p className="mb-4 text-gray-800"><strong>Security Task Force:</strong> Establish a joint security task force to monitor and respond to incidents of cable theft and vandalism. This task force will work collaboratively to safeguard our infrastructure.</p>
                <p className="mb-4 text-gray-800"><strong>Technological Solutions:</strong> Implement advanced technological solutions for the surveillance and protection of network infrastructure, ensuring the security of all network services. By leveraging technology, we can enhance our security measures.</p>
                <h3 className="text-xl font-semibold mb-2 text-blue-600">3. Legal Support and Rapid Response</h3>
                <p className="mb-4 text-gray-800"><strong>Legal Assistance:</strong> Provide legal assistance to members facing unjust actions or threats. Our legal support services are designed to protect the rights and interests of our members.</p>
                <p className="mb-4 text-gray-800"><strong>Quick Response:</strong> Develop a rapid response mechanism to address and resolve incidents of economic disruption, protecting the interests of all network equipment providers and service operators. Swift action is crucial in maintaining stability and security.</p>
                <h3 className="text-xl font-semibold mb-2 text-blue-600">4. Community Building and Support</h3>
                <p className="mb-4 text-gray-800"><strong>Regular Engagement:</strong> Encourage a sense of community among members through regular meetings, workshops, and collaborative projects. By encouraging regular interaction, we strengthen our network and promote knowledge sharing.</p>
                <p className="mb-4 text-gray-800"><strong>Support Network:</strong> Create a support network for sharing best practices and resources to enhance operational efficiency and security for all network service providers. Collaboration and mutual support are key to our collective success.</p>
                <h3 className="text-xl font-semibold mb-2 text-blue-600">5. Market Development and Fair Competition</h3>
                <p className="mb-4 text-gray-800"><strong>Ethical Standards:</strong> Promote fair competition by setting ethical standards and practices for all members. Our commitment to ethics ensures a level playing field and creates trust within the industry.</p>
                <p className="mb-4 text-gray-800"><strong>Business Opportunities:</strong> Organize trade fairs, seminars, and networking events to facilitate business opportunities and partnerships among ISPs, network service providers, and network equipment providers. These events provide platforms for collaboration and growth.</p>
                <h3 className="text-xl font-semibold mb-2 text-blue-600">Commitment to Excellence</h3>
                <p className='text-gray-800'>We, the members of the All Pakistan Network Association (APNA), commit ourselves to the pursuit of excellence in our industry. Through unity, innovation, and relentless advocacy, we aim to create a thriving, secure, and equitable environment for all ISPs, network service providers, and network equipment providers. Together, we will build a future where connectivity is seamless, secure, and accessible to all, ensuring that the benefits of a connected world are shared by everyone in Pakistan. Our dedication to excellence drives us to continuously improve and innovate, setting new standards for the industry and contributing to the overall progress of society.</p>
              </div>
            </div>
          </div>
        </div>
      </div>



















     








    </>
  );
};





















// import React, { useState } from 'react';

// export function Menifesto() {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isUrdu, setIsUrdu] = useState(false);

//   const toggleLanguage = () => {
//     setIsUrdu(!isUrdu);
//   };

//   return (
//     <>
//       <section className="bg-[#f5f5f5]">
//         <div className="container px-6 py-16 mx-auto text-center">
//           <div className="max-w-lg mx-auto">
//             <h1 className="text-3xl font-semibold text-blue-600 dark:text-white lg:text-4xl">
//               {isUrdu ? 'منشور' : 'Manifesto'}
//             </h1>
//             <p className="mt-6 text-gray-800 dark:text-gray-300">
//               {isUrdu
//                 ? 'آل پاکستان نیٹ ورک ایسوسی ایشن (APNA) کے ممبران تمام کمپنیوں اور آپریٹرز کے حقوق اور مفادات کے تحفظ کے لیے متحد ہیں۔ مذہب، قومیت، زبان یا پیشے سے قطع نظر، ہم ایک محفوظ اور ترقی پذیر ماحول بنانے کے لیے اجتماعی طور پر کام کرنے کا عہد کرتے ہیں۔ ہمارے اتحاد، شمولیت، اور ترقی کے عزم کا تعین اس وقت کیا جاتا ہے جب ہم اپنی صنعت کی وضاحت کرنے والے چیلنجز اور مواقع پر قابو پاتے ہیں۔'
//                 : 'The All Pakistan Network Association (APNA) members stand united to safeguard the rights and interests of all companies and operators in this sector. Regardless of religion, nationality, language, or profession, we pledge to work collectively to create a secure and thriving environment. Our commitment to unity, inclusivity, and progress is determined as we navigate the challenges and opportunities that define our industry.'}
//             </p>

//             <div className="text-center mt-5">
//               <a
//                 href="#"
//                 className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
//               >
//                 {isUrdu ? 'ہمارے ساتھ شامل ہوں' : 'Join Us'}
//               </a>
//             </div>
//           </div>

//           <div className="flex justify-center mt-10">
//             <img
//               className="object-cover w-full h-96 rounded-xl lg:w-4/5"
//               src="https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"
//               alt="Illustration"
//             />
//           </div>
//         </div>
//       </section>

//       <div className="container bg-[#f5f5f5] text-[#414dd7] max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto sm:mt-24">
//         <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-6">
//           <div className="lg:col-span-2">
//             <div className="py-8 lg:pe-8">
//               <div className="space-y-5 lg:space-y-8">
//                 <h2 className="text-3xl font-bold lg:text-5xl text-blue-600">
//                   {isUrdu ? 'آل پاکستان نیٹ ورک ایسوسی ایشن' : 'All Pakistan Network Association'}
//                 </h2>
//                 <div className="flex items-center gap-x-5">
//                   <h2 className="text-3xl font-bold lg:text-2xl text-blue-600">
//                     {isUrdu ? 'مقصد' : 'Objective'}
//                   </h2>
//                   <p className="text-xs sm:text-sm text-gray-800 dark:text-neutral-200">
//                     {isUrdu ? '18 جنوری 2023' : 'January 18, 2023'}
//                   </p>
//                 </div>
//                 <h2 className="text-1xl font-bold lg:text-1xl text-blue-600">
//                   {isUrdu ? '1. اتحاد اور شمولیت' : '1. Unity and Inclusivity'}
//                 </h2>
//                 <p className="text-lg text-gray-800 dark:text-neutral-200">
//                   <span className='text-blue-600'>
//                     {isUrdu ? 'یکجہتی پلیٹ فارم:' : 'Unified Platform:'}
//                   </span>
//                   <p className='text-gray-800'>
//                     {isUrdu
//                       ? 'تمام ISPs، نیٹ ورک سروس فراہم کرنے والوں، نیٹ ورک سروسز، اور آپریٹرز کو ایک متحد پلیٹ فارم پر لانا، مذہب، قومیت، زبان یا پیشے سے قطع نظر اتحاد کو فروغ دینا۔ اپنی کوششوں کو یکجا کرکے، ہم ایک مضبوط، زیادہ مربوط صنعت بنانے کا ارادہ رکھتے ہیں۔'
//                       : 'Bring together all ISPs, network service providers, network services, and operators on a unified platform, promoting unity irrespective of religion, nationality, language, or profession. By consolidating our efforts, we aim to build a stronger, more cohesive industry.'}
//                   </p>
//                 </p>
//                 <p className="text-lg text-gray-800 dark:text-neutral-200">
//                   {isUrdu
//                     ? 'تعاون کو فروغ دینا: ممبران کے درمیان شمولیت اور تعاون کو فروغ دینا تاکہ مشترکہ اہداف حاصل کیے جا سکیں اور ہماری اجتماعی آواز کو مضبوط بنایا جا سکے۔ تنوع کو اپنانے اور مل کر کام کرنے سے، ہم بہتر طریقے سے ان چیلنجوں کا مقابلہ کر سکتے ہیں جن کا ہم سامنا کرتے ہیں اور مواقع سے فائدہ اٹھا سکتے ہیں۔'
//                     : 'Promoting Cooperation: Encourage inclusivity and cooperation among members to achieve common goals and strengthen our collective voice. By embracing diversity and working together, we can better address the challenges we face and capitalize on opportunities.'}
//                 </p>
//                 <div className="text-center">
//                   <div className="grid lg:grid-cols-2 gap-3">
//                     <div className="grid grid-cols-2 lg:grid-cols-1 gap-3">
//                       <figure className="relative w-full h-60">
//                         <img
//                           className="size-full absolute top-0 start-0 object-cover rounded-xl"
//                           src="https://images.unsplash.com/photo-1670272505340-d906d8d77d03?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
//                           alt="Image Description"
//                         />
//                       </figure>
//                       <figure className="relative w-full h-60">
//                         <img
//                           className="size-full absolute top-0 start-0 object-cover rounded-xl"
//                           src="https://images.unsplash.com/photo-1671726203638-83742a2721a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
//                           alt="Image Description"
//                         />
//                       </figure>
//                     </div>
//                     <figure className="relative w-full h-72 sm:h-96 lg:h-full">
//                       <img
//                         className="size-full absolute top-0 start-0 object-cover rounded-xl"
//                         src="https://images.unsplash.com/photo-1671726203394-491c8b574a0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8&auto=format&fit=crop&w=988&q=80"
//                         alt="Image Description"
//                       />
//                     </figure>
//                   </div>
//                   <span className="mt-3 block text-sm text-center text-gray-800 dark:text-neutral-500">
//                     {isUrdu ? 'کام کرنے کا عمل' : 'Working process'}
//                   </span>
//                 </div>
//                 <h2 className="text-1xl font-bold lg:text-1xl text-blue-600">
//                   {isUrdu ? '2. کاروبار کے لیے سازگار ماحول' : '2. Business-Friendly Environment'}
//                 </h2>
//                 <p className="text-lg text-gray-800 dark:text-neutral-200">
//                   {isUrdu
//                     ? 'رکاوٹوں کو روکنا: مسلسل اور قابل اعتماد نیٹ ورک سروسز کو یقینی بنانے کے لیے کیبل چوری، کیبل کٹنے، اور دیگر رکاوٹوں کو روکنے کے اقدامات پر عمل درآمد۔ ان مسائل کو کم سے کم کرنے سے، ہم اپنے صارفین کے لیے ہموار تجربہ فراہم کرنے کے قابل ہیں۔'
//                     : 'Preventing Disruptions: Implement measures to prevent cable theft, cable cuts, and other disruptions to ensure continuous and reliable network services. Minimizing these issues enables us to provide a seamless experience for our customers.'}
//                 </p>
//                 <p className="text-lg text-gray-800 dark:text-neutral-200">
//                   {isUrdu
//                     ? 'کاروباری ترقی کی حمایت: کاروبار کی ترقی کی حمایت اور ایک سازگار ماحول پیدا کرنے کے لیے انڈسٹری کے اندر ہم آہنگی، جدت اور سرمایہ کاری کو فروغ دینا۔ ہم اپنے اراکین کو پروان چڑھنے اور ان کے مقاصد کو حاصل کرنے میں مدد کرنے کے لیے پرعزم ہیں۔'
//                     : 'Supporting Business Growth: Foster collaboration, innovation, and investment within the industry to support business growth and create a conducive environment. We are committed to helping our members thrive and achieve their goals.'}
//                 </p>
//                 <h2 className="text-1xl font-bold lg:text-1xl text-blue-600">
//                   {isUrdu ? '3. معیار اور بہترین طرز عمل' : '3. Quality and Best Practices'}
//                 </h2>
//                 <p className="text-lg text-gray-800 dark:text-neutral-200">
//                   {isUrdu
//                     ? 'معیار کی یقین دہانی: نیٹ ورک کی خدمات کے معیار کو بلند کرنا اور اعلیٰ سطح کی سروس ڈیلیوری کو یقینی بنانے کے لیے بہترین طریقوں کو اپنانا۔ اپنے صارفین کی توقعات سے تجاوز کرکے، ہم اپنے شعبے میں اعتماد اور اعتماد پیدا کرتے ہیں۔'
//                     : 'Ensuring Quality: Elevate the standards of network services and adopt best practices to ensure high levels of service delivery. By exceeding our customers\' expectations, we build trust and confidence in our sector.'}
//                 </p>
//                 <p className="text-lg text-gray-800 dark:text-neutral-200">
//                   {isUrdu
//                     ? 'مسلسل بہتری: اپنے آپریشنز اور خدمات میں مسلسل بہتری کی کوشش کریں، مستقل مزاجی اور مسابقت کو یقینی بنانے کے لیے اراکین کو اپ ڈیٹس، تربیت، اور وسائل فراہم کریں۔'
//                     : 'Continuous Improvement: Strive for continuous improvement in our operations and services, providing members with updates, training, and resources to ensure consistency and competitiveness.'}
//                 </p>
//               </div>
//               <div className="mt-4">
//                 <button
//                   onClick={() => setIsOpen(!isOpen)}
//                   className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
//                 >
//                   {isUrdu ? 'مزید' : 'More'}
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className="relative lg:py-16">
//             <div className="overflow-hidden bg-gray-100 rounded-xl">
//               <img
//                 className="object-cover scale-[1.1] w-full h-[32rem] lg:h-full"
//                 src="https://images.unsplash.com/photo-1677778017068-b29985382432?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
//                 alt="Image Description"
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       {isOpen && (
//         <div className="container bg-[#f5f5f5] text-[#414dd7] max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto mt-5 sm:mt-24">
//           <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-6">
//             <div className="lg:col-span-2">
//               <div className="py-8 lg:pe-8">
//                 <div className="space-y-5 lg:space-y-8">
//                   <p className="text-lg text-gray-800 dark:text-neutral-200">
//                     {isUrdu
//                       ? 'ہم نیک نیتی، شفافیت اور احتساب کے اپنے اصولوں پر سختی سے قائم ہیں، اور ہم اپنے عمل اور کاروباری طریقوں میں شفافیت کو فروغ دیتے ہیں۔'
//                       : 'We strictly adhere to our principles of integrity, transparency, and accountability, and we promote transparency in our operations and business practices.'}
//                   </p>
//                   <p className="text-lg text-gray-800 dark:text-neutral-200">
//                     {isUrdu
//                       ? 'رکاوٹوں کو روکنا: مسلسل اور قابل اعتماد نیٹ ورک سروسز کو یقینی بنانے کے لیے کیبل چوری، کیبل کٹنے، اور دیگر رکاوٹوں کو روکنے کے اقدامات پر عمل درآمد۔ ان مسائل کو کم سے کم کرنے سے، ہم اپنے صارفین کے لیے ہموار تجربہ فراہم کرنے کے قابل ہیں۔'
//                       : 'Preventing Disruptions: Implement measures to prevent cable theft, cable cuts, and other disruptions to ensure continuous and reliable network services. Minimizing these issues enables us to provide a seamless experience for our customers.'}
//                   </p>
//                   <p className="text-lg text-gray-800 dark:text-neutral-200">
//                     {isUrdu
//                       ? 'ہم اپنے آپریشنز اور خدمات میں مسلسل بہتری کی کوشش کرتے ہیں، مستقل مزاجی اور مسابقت کو یقینی بنانے کے لیے اراکین کو اپ ڈیٹس، تربیت، اور وسائل فراہم کرتے ہیں۔'
//                       : 'We strive for continuous improvement in our operations and services, providing members with updates, training, and resources to ensure consistency and competitiveness.'}
//                   </p>
//                   <p className="text-lg text-gray-800 dark:text-neutral-200">
//                     {isUrdu
//                       ? 'مسلسل بہتری: ہم اپنے آپریشنز اور خدمات میں مسلسل بہتری کی کوشش کرتے ہیں، مستقل مزاجی اور مسابقت کو یقینی بنانے کے لیے اراکین کو اپ ڈیٹس، تربیت، اور وسائل فراہم کرتے ہیں۔'
//                       : 'Continuous Improvement: Strive for continuous improvement in our operations and services, providing members with updates, training, and resources to ensure consistency and competitiveness.'}
//                   </p>
//                 </div>
//               </div>
//             </div>

//             <div className="relative lg:py-16">
//               <div className="overflow-hidden bg-gray-100 rounded-xl">
//                 <img
//                   className="object-cover scale-[1.1] w-full h-[32rem] lg:h-full"
//                   src="https://images.unsplash.com/photo-1677778017068-b29985382432?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
//                   alt="Image Description"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       <div className="text-center mt-5">
//         <button
//           onClick={toggleLanguage}
//           className="inline-block rounded bg-blue-600 px-4 py-2 text-sm font-medium text-white transition hover:bg-blue-700 focus:outline-none focus:ring focus:ring-yellow-400"
//         >
//           {isUrdu ? 'Switch to English' : 'اردو میں تبدیل کریں'}
//         </button>
//       </div>
//     </>
//   );
// }













