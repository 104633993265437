import React from 'react'
import './a.css'
import { useEffect } from 'react';
export function A() {
  useEffect(() => {
    const section = document.querySelector('.animated-section');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in-left');
          } else {
            entry.target.classList.remove('slide-in-left');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);
  return (<>   
   {/* #3bc3ef */}
   {/* #414dd7 */}
    <section className=" relative -top-16 w-full h-screen section-background animate-slide-in-left">
      <div className="absolute inset-0 sm:bg-transparent sm:ltr:bg-gradient-to-r sm:rtl:bg-gradient-to-l" />
      <section className="text-white">
        <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
          <div className="mx-auto max-w-3xl text-center">
           
            <h1 className="bg-gradient-to-r from-pink-300 via-pink-300 to-pink-300 bg-clip-text text-2xl font-extrabold text-transparent sm:text-1xl">
              APNA, Union excels in delivering excellence in internet and network services and equipment, fueling your digital ambitions.
              <span className="sm:block"> Increase Conversion. </span>
            </h1>
            <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
              Uncover the power of Union's rebellious solutions to voice the true potential of your digital world.
            </p>
           
          </div>
        </div>
      </section>
    </section>

  </>
  )
}
