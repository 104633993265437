
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
export function Com_Table() {
    const [data, setData] = useState([]);
    // console.log(data);

    const [MembersData, setUserData] = useState([]);
    const [isInfoOpenModal, setIsOpenModal] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentCommitteeId, setCurrentCommitteeId] = useState(null);
    const [newCommitteeName, setNewCommitteeName] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);

    // Fetch committee data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get('https://apna.org.pk/apna.org.pk/api/commite');
                setData(res.data.data);
            } catch (err) {
                console.error('Error fetching committee data:', err);
            }
        };
        fetchData();
    }, [data]);

    // Fetch members data
    useEffect(() => {
        axios.get('https://apna.org.pk/apna.org.pk/api/user')
            .then((res) => {
                setUserData(res.data.user);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [MembersData]);

    const options = MembersData.map(user => ({
        value: user.email,
        label: user.first_name,
        ID: user.id
    }));


    const openInfoModal = (committee) => {
        setCurrentCommitteeId(committee.id);
        setSelectedOptions([]);
        setIsOpenModal(true);
    };

    const closeInfoModal = () => {
        setIsOpenModal(false);
        setSelectedOptions([]);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://apna.org.pk/apna.org.pk/api/deletecommite/${id}`);
            setData(data.filter(item => item.id !== id));
            console.log('Committee deleted successfully!');
        } catch (err) {
            console.error('Error response:', err.response);
        }
    };


    const handleChange = (selected) => {
        setSelectedOptions(selected);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const committee = data.find(item => item.id === currentCommitteeId);

        const submissionData = {
            committeeId: currentCommitteeId,
            committeeName: committee.committe,
            user_id: selectedOptions.map(option => option.ID),
        };

        try {
            // console.log('Submitted Data:', submissionData);
            const response = await axios.post(`https://apna.org.pk/apna.org.pk/api/commitemem/${currentCommitteeId}`, submissionData);
            console.log('Response from API:', response.data);
            closeInfoModal();
        } catch (err) {
            console.error('Error submitting data:', err);
        }
    };
    const openEditModal = (committee) => {
        setCurrentCommitteeId(committee.id);
        setNewCommitteeName(''); 
        setIsEditModalOpen(true);
    };



    const { register, handleSubmit } = useForm()


    const handleEditSubmit = async (data) => {
        const { committe } = data;

        try {
            await axios.put(`https://apna.org.pk/apna.org.pk/api/editcommite/${currentCommitteeId}`, { 'committe': committe });
        } catch (err) {
            console.error('Error updating committee:', err);
            console.error('Response data:', err.response.data);
        }
        setIsEditModalOpen(false);
    };
    return (
        <>
            {data.map((Com) => (
                <tr key={Com.id}>
                    <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                        <div className="inline-flex items-center gap-x-3">
                            <h2 className="font-medium text-gray-800 dark:text-white">{Com.committe}</h2>
                        </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                        <div className="flex items-center gap-x-2">
                            <button
                                className="px-3 py-1 text-xs text-indigo-500 rounded-full dark:bg-gray-800 bg-indigo-100/60"
                                onClick={() => openInfoModal(Com)}
                            >
                                Info
                            </button>
                            <button
                                className="px-3 py-1 text-xs text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60"
                                onClick={() => openEditModal(Com)}
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDelete(Com.id)}
                                className="px-3 py-1 text-xs text-pink-500 rounded-full dark:bg-gray-800 bg-pink-100/60"
                            >
                                Delete
                            </button>
                        </div>
                    </td>
                </tr>
            ))}

            {/* Info Modal */}
            {isInfoOpenModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full relative">
                        <h2 className="text-2xl font-bold mb-4">Modal Title</h2>
                        <form onSubmit={onSubmit}>
                            <div className="flex flex-wrap justify-between gap-4">
                                <div className="flex-1 min-w-[200px] max-w-[300px] p-4 rounded-lg">
                                    <Select
                                        defaultValue={[]}
                                        isMulti
                                        name="users"
                                        options={options}
                                        onChange={handleChange}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px] max-w-[300px] p-4 rounded-lg">
                                    <input
                                        type="text"
                                        placeholder='Committee Name'
                                        value={data.find(item => item.id === currentCommitteeId)?.committe || ''}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 mt-4"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 mt-4 ml-2"
                                onClick={closeInfoModal}
                            >
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}

            {/* Edit Modal */}
            {isEditModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full relative">
                        <h2 className="text-2xl font-bold mb-4">Edit Committee</h2>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <div className="flex flex-wrap justify-between gap-4">
                                <div className="flex-1 min-w-[200px] max-w-[300px] p-4 rounded-lg">
                                    <input
                                        type="text"
                                        placeholder='New Committee Name'
                                        {...register('committe', { required: true })}
                                    />
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 mt-4"
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 mt-4 ml-2"
                                onClick={() => setIsEditModalOpen(false)}
                            >
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}


        </>
    );
}

























// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Select from 'react-select';
// import { useForm } from 'react-hook-form';

// export function Com_Table() {
//     const [data, setData] = useState([]);
//     const [MembersData, setUserData] = useState([]);
//     const [isInfoOpenModal, setIsOpenModal] = useState(false);
//     const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//     const [currentCommitteeId, setCurrentCommitteeId] = useState(null);
//     const [newCommitteeName, setNewCommitteeName] = useState('');
//     const [selectedOptions, setSelectedOptions] = useState([]);

//     // Fetch committee data
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const res = await axios.get('https://apna.org.pk/apna.org.pk/api/commite');
//                 setData(res.data.data);
//             } catch (err) {
//                 console.error('Error fetching committee data:', err);
//             }
//         };
//         fetchData();
//     }, []);

//     // Fetch members data
//     useEffect(() => {
//         const fetchMembersData = async () => {
//             try {
//                 const res = await axios.get('https://apna.org.pk/apna.org.pk/api/user');
//                 setUserData(res.data.user);
//             } catch (err) {
//                 console.log(err);
//             }
//         };
//         fetchMembersData();
//     }, []);

//     const options = MembersData.map(user => ({
//         value: user.email,
//         label: user.first_name,
//         ID: user.id
//     }));

//     const openInfoModal = (committee) => {
//         setCurrentCommitteeId(committee.id);
//         setSelectedOptions([]);
//         setIsOpenModal(true);
//     };

//     const closeInfoModal = () => {
//         setIsOpenModal(false);
//         setSelectedOptions([]);
//     };

//     const handleDelete = async (id) => {
//         try {
//             await axios.delete(`https://apna.org.pk/apna.org.pk/api/deletecommite/${id}`);
//             setData(data.filter(item => item.id !== id));
//             console.log('Committee deleted successfully!');
//         } catch (err) {
//             console.error('Error response:', err.response);
//         }
//     };

//     const handleChange = (selected) => {
//         setSelectedOptions(selected);
//     };

//     const onSubmit = async (e) => {
//         e.preventDefault();
//         const committee = data.find(item => item.id === currentCommitteeId);

//         const submissionData = {
//             committeeId: currentCommitteeId,
//             committeeName: committee.committe,
//             user_id: selectedOptions.map(option => option.ID),
//         };

//         try {
//             const response = await axios.post(`https://apna.org.pk/apna.org.pk/api/commitemem/${currentCommitteeId}`, submissionData);
//             console.log('Response from API:', response.data);
//             closeInfoModal();
//         } catch (err) {
//             console.error('Error submitting data:', err);
//         }
//     };

//     const openEditModal = (committee) => {
//         setCurrentCommitteeId(committee.id);
//         setNewCommitteeName(committee.committe); // Set the current committee name
//         setIsEditModalOpen(true);
//     };

//     const { register, handleSubmit } = useForm();

//     const handleEditSubmit = async (data) => {
//         const { committe } = data;

//         try {
//             await axios.put(`https://apna.org.pk/apna.org.pk/api/editcommite/${currentCommitteeId}`, { 'committe': committe });
//             console.log('Committee updated successfully!');
//             setNewCommitteeName(''); // Clear the input after successful update
//         } catch (err) {
//             console.error('Error updating committee:', err);
//             console.error('Response data:', err.response.data);
//         }
//         setIsEditModalOpen(false);
//     };

//     return (
//         <>
//             {data.map((Com) => (
//                 <tr key={Com.id}>
//                     <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
//                         <div className="inline-flex items-center gap-x-3">
//                             <h2 className="font-medium text-gray-800 dark:text-white">{Com.committe}</h2>
//                         </div>
//                     </td>
//                     <td className="px-4 py-4 text-sm whitespace-nowrap">
//                         <div className="flex items-center gap-x-2">
//                             <button
//                                 className="px-3 py-1 text-xs text-indigo-500 rounded-full dark:bg-gray-800 bg-indigo-100/60"
//                                 onClick={() => openInfoModal(Com)}
//                             >
//                                 Info
//                             </button>
//                             <button
//                                 className="px-3 py-1 text-xs text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60"
//                                 onClick={() => openEditModal(Com)}
//                             >
//                                 Edit
//                             </button>
//                             <button
//                                 onClick={() => handleDelete(Com.id)}
//                                 className="px-3 py-1 text-xs text-pink-500 rounded-full dark:bg-gray-800 bg-pink-100/60"
//                             >
//                                 Delete
//                             </button>
//                         </div>
//                     </td>
//                 </tr>
//             ))}

//             {/* Info Modal */}
//             {isInfoOpenModal && (
//                 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                     <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full relative">
//                         <h2 className="text-2xl font-bold mb-4">Modal Title</h2>
//                         <form onSubmit={onSubmit}>
//                             <div className="flex flex-wrap justify-between gap-4">
//                                 <div className="flex-1 min-w-[200px] max-w-[300px] p-4 rounded-lg">
//                                     <Select
//                                         defaultValue={[]}
//                                         isMulti
//                                         name="users"
//                                         options={options}
//                                         onChange={handleChange}
//                                         getOptionLabel={(option) => option.label}
//                                         getOptionValue={(option) => option.value}
//                                         className="basic-multi-select"
//                                         classNamePrefix="select"
//                                     />
//                                 </div>
//                                 <div className="flex-1 min-w-[200px] max-w-[300px] p-4 rounded-lg">
//                                     <input
//                                         type="text"
//                                         placeholder='Committee Name'
//                                         value={data.find(item => item.id === currentCommitteeId)?.committe || ''}
//                                         readOnly
//                                     />
//                                 </div>
//                             </div>
//                             <button
//                                 type="submit"
//                                 className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 mt-4"
//                             >
//                                 Submit
//                             </button>
//                             <button
//                                 type="button"
//                                 className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 mt-4 ml-2"
//                                 onClick={closeInfoModal}
//                             >
//                                 Cancel
//                             </button>
//                         </form>
//                     </div>
//                 </div>
//             )}

//             {/* Edit Modal */}
//             {isEditModalOpen && (
//                 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                     <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full relative">
//                         <h2 className="text-2xl font-bold mb-4">Edit Committee</h2>
//                         <form onSubmit={handleSubmit(handleEditSubmit)}>
//                             <div className="flex flex-wrap justify-between gap-4">
//                                 <div className="flex-1 min-w-[200px] max-w-[300px] p-4 rounded-lg">
//                                     <input
//                                         type="text"
//                                         placeholder='New Committee Name'
//                                         value={newCommitteeName} // Bind to newCommitteeName
//                                         onChange={(e) => setNewCommitteeName(e.target.value)} // Update on change
//                                         {...register('committe', { required: true })}
//                                     />
//                                 </div>
//                             </div>
//                             <button
//                                 type="submit"
//                                 className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 mt-4"
//                             >
//                                 Save
//                             </button>
//                             <button
//                                 type="button"
//                                 className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 mt-4 ml-2"
//                                 onClick={() => {
//                                     setIsEditModalOpen(false);
//                                     setNewCommitteeName(''); // Clear the input when closing the modal
//                                 }}
//                             >
//                                 Cancel
//                             </button>
//                         </form>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// }
