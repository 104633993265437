import React from 'react'
// import { Admin_Login } from './Admin_Login/Admin_Login'
import { Nav } from './Nav/Nav'
import { Sidebar } from './Sidebar/Sidebar'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function Admin() {

    const [authorized, setauthorized] = useState()
    const [auth, setauth] = useState(false)
    const Navigate = useNavigate()




    const postData = async () => {

        const token = localStorage.getItem('token')
        if (!token) {
            Navigate('/login');
            return;
        }

        try {
            const response = await axios.post(
                'https://apna.org.pk/apna.org.pk/api/check-token',
                {
                    first_name: "",
                    email: '',
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.message) {
                setauthorized(response.data.user);
            }
            //   console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error.response);
        }
    };
    useEffect(() => {
        postData();
    }, [])


    return (
        <>
            <Sidebar />
            <div className="p-4 sm:ml-64">
                <div className="p-4">
                    <div className="flex flex-col items-center py-5 ">
                        <h2 className="text-2xl font-semibold mb-4 text-blue-600">Membership</h2>
                        <div className="flex flex-wrap justify-center max-w-4xl w-full shadow-lg p-6 bg-white rounded-lg">
                            {/* Left: Membership Card */}
                            <div style={{ height: '230px' }} className="mt-14 bg-gradient-to-r from-green-400 to-teal-500 p-6 rounded-lg shadow-md text-white w-full md:w-1/2">
                                <div className="flex justify-start">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 3c1.333 1.667 4 3.333 4 6 0 2-1.167 3.333-4 4m0 0c-2.833-.667-4-2-4-4 0-2.667 2.667-4.333 4-6zm0 0v9"
                                        />
                                    </svg>
                                </div>
                                <div className="mt-6">
                                    <h3 className="text-3xl font-bold mb-4">6162 7089 6671 7180</h3>
                                    <div className="flex justify-between">
                                        <div>
                                            <p className="font-semibold">HOLDER</p>
                                            <p>Saqib Ayaz</p>
                                        </div>
                                        <div>
                                            <p className="font-semibold">SINCE</p>
                                            <p>09/24</p>
                                        </div>
                                        <div>
                                            <p className="font-semibold">EXPIRES</p>
                                            <p>-</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Right: Membership Details */}
                            <div className="flex flex-col justify-between p-6 w-full md:w-1/2">
                                <div>
                                    <p className="font-semibold">MEMBERSHIP ID</p>
                                    <p>6162708966717180</p>
                                </div>
                                <div className="mt-4">
                                    <p className="font-semibold">STATUS</p>
                                    <p>Active</p>
                                </div>
                                <div className="mt-4">
                                    <p className="font-semibold">PACKAGE</p>
                                    <p>Seed</p>
                                </div>
                                <div className="mt-4">
                                    <p className="font-semibold">BILLING</p>
                                    <p>Fixed</p>
                                </div>
                                <div className="mt-4">
                                    <p className="font-semibold">MEMBERSHIP CERTIFICATE</p>
                                    <a
                                        href="#"
                                        className="text-orange-600 hover:underline"
                                    >
                                        Download your certificate
                                    </a>
                                </div>
                            </div>


                            {/* MEMBERSHIP  */}

                            <div className="flex flex-col items-center">
                                <h2 className="text-3xl font-bold mb-6">Packages</h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-4xl">
                                    {/* Seed Package */}
                                    <div className="border rounded-lg shadow-md p-6">
                                        <h3 className="text-xl font-semibold mb-4">SEED</h3>
                                        <div className="text-4xl font-bold mb-4">Free</div>
                                        <button className="bg-black text-white font-semibold py-2 px-4 rounded mb-6">
                                            ACTIVE
                                        </button>
                                        <ul className="space-y-2">
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> Voice & Support Group
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> Discount & Promotions
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> Exclusive Events
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> Membership Certificate
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> Remote internet payment gateway
                                            </li>
                                        </ul>
                                    </div>

                                    {/* Surge Package */}
                                    <div className="border rounded-lg shadow-md p-6">
                                        <h3 className="text-xl font-semibold mb-4">SURGE</h3>
                                        <div className="text-4xl font-bold mb-4">PKR 500/mo</div>
                                        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded mb-6">
                                            SUBSCRIBE
                                        </button>
                                        <ul className="space-y-2">
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> All in Seed +
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> Annual Tax Filing
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> Accidental Health Insurance
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> Workshops and Trainings
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✔</span> Exclusive walk-in access to events
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
