import React from 'react'
import { Sidebar } from '../Sidebar';
export function Order_History() {
    const orders = [
        {
            date: '2023-09-01',
            amount: '500 PKR',
            paymentMethod: 'Credit Card',
            paidFor: 'Membership',
        },
        {
            date: '2023-09-10',
            amount: '1500 PKR',
            paymentMethod: 'Bank Transfer',
            paidFor: 'Workshops',
        },
    ];
    return (
        <>
            <Sidebar />
            <div className="p-4 sm:ml-64">
                <div className="p-4">
                    <div className="container mx-auto p-6">
                        {/* Packages Section */}

                        {/* Order History Section */}
                        <div className="bg-white shadow-md rounded-lg p-6">
                            <h2 className="text-2xl font-bold mb-4">Order History</h2>
                            {orders.length > 0 ? (
                                <table className="w-full text-left table-auto">
                                    <thead>
                                        <tr className="border-b">
                                            <th className="py-2">Date</th>
                                            <th className="py-2">Amount</th>
                                            <th className="py-2">Payment Method</th>
                                            <th className="py-2">Paid for</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((order, index) => (
                                            <tr key={index} className="border-b">
                                                <td className="py-2">{order.date}</td>
                                                <td className="py-2">{order.amount}</td>
                                                <td className="py-2">{order.paymentMethod}</td>
                                                <td className="py-2">{order.paidFor}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="flex flex-col items-center justify-center h-64">
                                    <svg
                                        className="w-12 h-12 text-gray-400 mb-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 4v16m8-8H4"
                                        />
                                    </svg>
                                    <p className="text-gray-500">No data</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
