import React from 'react'
import { Sidebar } from '../Sidebar'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowUp, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export function Req_Dashboard() {
    return (<>
        <Sidebar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">

                <h1 className='mb-10 text-blue-600 lg:text-xl font-medium'>Request Dashboard</h1>
                <div className='flex  gap-4 md:gap-6 lg:gap-16 overflow-x-auto'>
                    {/* Card 1 */}
                    <div className="flex-shrink-0 relative flex flex-col items-center justify-between w-24 h-24 md:w-32 md:h-32 lg:w-30 lg:h-30">
                        {/* Top Arrow Icon - pointing to the right */}
                        <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />

                        {/* Left and Right Arrows */}
                        <div className="flex justify-between w-full px-2">
                            <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
                            <span className="text-center text-xs md:text-sm text-red-600 font-semibold lg:text-xl">0</span>
                            <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                        </div>

                        {/* Bottom Heading */}
                        <h3 className="mb-1 text-xs md:text-sm lg:text-base text-blue-600">Add Request</h3>
                    </div>

                    {/* Card 2 */}
                    <div className="flex-shrink-0 relative flex flex-col items-center justify-between w-24 h-24 md:w-32 md:h-32 lg:w-30 lg:h-30">
                        <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />

                        <div className="flex justify-between w-full px-2">
                            <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
                            <span className="text-center md:text-sm text-blue-600 font-semibold lg:text-xl ">1</span>
                            <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                        </div>

                        <h3 className="mb-1 text-xs md:text-sm lg:text-base text-blue-600">Request Check</h3>
                    </div>

                    {/* Card 3 */}
                    <div className="flex-shrink-0 relative flex flex-col items-center justify-between w-24 h-24 md:w-32 md:h-32 lg:w-30 lg:h-30">
                        <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />

                        <div className="flex justify-between w-full px-2">
                            <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
                            <span className="text-center text-xs md:text-sm text-red-600 font-semibold lg:text-xl ">0</span>
                            <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                        </div>

                        <h3 className="mb-1 text-xs md:text-sm lg:text-base text-blue-600">Request Transfer</h3>
                    </div>

                    {/* Card 4 */}
                    <div className="flex-shrink-0 relative flex flex-col items-center justify-between w-24 h-24 md:w-32 md:h-32 lg:w-30 lg:h-30">
                        <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />

                        <div className="flex justify-between w-full px-2">
                            <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
                            <span className="text-center text-xs md:text-sm text-red-600 font-semibold lg:text-xl">0</span>
                            <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                        </div>

                        <h3 className="mb-1 text-xs md:text-sm lg:text-base text-blue-600">Request Approvel</h3>
                    </div>
                </div>


            </div>
        </div>

    </>
    )
}












// <div className='flex justify-center gap-28'>




// <div className="relative flex flex-col items-center justify-between w-[100px] h-[100px] ">
//     {/* Top Arrow Icon - pointing to the right */}

//     <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />



//     {/* Left and Right Arrows */}
//     <div className="flex justify-between w-full px-2">
//         <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
//         <span className="text-center">788</span>
//         <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
//     </div>

//     {/* Bottom Heading */}
//     <h3 className="mb-1 text-sm">Heading</h3>
// </div>


// <div className="relative flex flex-col items-center justify-between w-[100px] h-[100px] ">
//     {/* Top Arrow Icon - pointing to the right */}

//     <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />



//     {/* Left and Right Arrows */}
//     <div className="flex justify-between w-full px-2">
//         <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
//         <span className="text-center">788</span>
//         <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
//     </div>

//     {/* Bottom Heading */}
//     <h3 className="mb-1 text-sm">Heading</h3>
// </div>


// <div className="relative flex flex-col items-center justify-between w-[100px] h-[100px] ">
//     {/* Top Arrow Icon - pointing to the right */}

//     <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />



//     {/* Left and Right Arrows */}
//     <div className="flex justify-between w-full px-2">
//         <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
//         <span className="text-center">788</span>
//         <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
//     </div>

//     {/* Bottom Heading */}
//     <h3 className="mb-1 text-sm">Heading</h3>
// </div>



// <div className="relative flex flex-col items-center justify-between w-[100px] h-[100px] ">
//     {/* Top Arrow Icon - pointing to the right */}

//     <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />



//     {/* Left and Right Arrows */}
//     <div className="flex justify-between w-full px-2">
//         <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
//         <span className="text-center">788</span>
//         <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
//     </div>

//     {/* Bottom Heading */}
//     <h3 className="mb-1 text-sm">Heading</h3>
// </div>


// </div>
