import React from 'react'
import { Link } from 'react-router-dom';

export function Web_Committee() {
  return (<>
    <>
      <div
        className="flex h-screen justify-center items-center bg-cover bg-center dark:bg-slate-800"
        style={{ backgroundImage: "url('https://img.freepik.com/free-vector/worldwide-connection-gray-background-illustration-vector_53876-76825.jpg?t=st=1722834298~exp=1722837898~hmac=d981116ce5d5632e49733a13a39d303f0e6e90dad6f575994ba80d71f953ed19&w=826')" }} // Replace this URL with your image URL
      >
        <div className="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8  bg-opacity-70 p-8 rounded-lg ">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">
                {/* <span className="mb-1 block">Create amazing</span> */}
                <span className="bg-gradient-to-r from-indigo-400 to-pink-600 bg-clip-text text-transparent">
                  Our Committee Board
                </span>
              </span>
            </h1>
            <p className="mx-auto mt-3 max-w-xl text-lg text-gray-800 dark:text-slate-800 sm:mt-5 md:mt-5">
              At Our Board, we unite ISPs, network service providers, and operators to create a secure and thriving environment. Join us in fostering connectivity and innovation across Pakistan.
            </p>
          </div>
        </div>
      </div>

    </>

    <>
      {/* committee section  */}
      <section className=" bg-[#f5f5f5]  mt-40">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div className="mx-auto max-w-lg text-center ">
            <h2 className="text-3xl font-bold sm:text-4xl text-blue-600">
              Meet Our Committee Members
            </h2>

            <div className="w-full max-w-8xl mx-auto">
              <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
                Our committee members at Our Board are a diverse group of experienced professionals dedicated to advancing the interests of ISPs, network service providers, and operators.
              </p>
            </div>




          </div>
          <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 ">
            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">IT Comittee</h2>

              <p className="mt-1 text-sm text-gray-800">
                Our committee is dedicated to driving impactful initiatives and fostering collaboration. We focus on bringing together experts and stakeholders to develop innovative solutions and address key challenges.
              </p>

            </Link>

            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">Events Committee</h2>

              <p className="mt-1 text-sm text-gray-800">
                The Events Committee is responsible for organizing and executing a wide range of events, from small gatherings to large-scale conferences.
              </p>

            </Link>
            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">Legal Committee</h2>
              <p className="mt-1 text-sm text-gray-800">
                <p className="mt-1 text-sm text-gray-800">
                  The Legal Committee is tasked with overseeing and ensuring compliance with legal and regulatory requirements.
                </p>

              </p>
            </Link>
            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">Membership Committee</h2>

              <p className="mt-1 text-sm text-gray-800">
                The Membership Committee is dedicated to growing and maintaining a vibrant and engaged membership base.
              </p>

            </Link>

            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">R&D Committee</h2>
              <p className="mt-1 text-sm text-gray-800">
                The R&D Committee is focused on driving innovation and advancing research within our organization.
              </p>

            </Link>

          </div>
        
        </div>
      </section>
      {/* </div> */}

    </>
  </>
  )
}
