import React from 'react'

export function About() {
    return (<>
        <div className="flex flex-col md:flex-row p-4 bg-[#f5f5f5] mt-16">
            {/* Content Column */}
            <div className="md:w-1/2 p-4">
                <h2 className="text-2xl font-bold mb-4">Who We Are</h2>
                <p className="mb-4">
                    APNA brings together ISPs and network operators from diverse backgrounds, including various religions, nationalities, languages, and professions. This assortment strengthens our association, enabling us to address a wide range of challenges and opportunities in the network sector.
                </p>
                <h3 className="text-xl font-semibold mb-2">What We Do</h3>
                <ul className="list-disc list-inside space-y-2">
                    <li><strong>Advocacy:</strong> We engage with government bodies and regulatory authorities to influence policies that benefit our members and the broader industry.</li>
                    <li><strong>Security:</strong> We implement measures to protect our members' infrastructure from threats such as cable theft and vandalism.</li>
                    <li><strong>Support:</strong> We provide legal assistance and rapid response support to members facing unjust actions or threats.</li>
                    <li><strong>Community Building:</strong> We cultivate a sense of community through regular meetings, workshops, and collaborative projects.</li>
                    <li><strong>Market Development:</strong> We promote fair competition and organize events to facilitate business opportunities and partnerships.</li>
                </ul>
            </div>

            {/* Image Column */}
            <div className="md:w-1/2 p-4">
                <img
                    src="https://images.unsplash.com/photo-1630450202872-e0829c9d6172?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                    alt="Description of image"
                    className="w-full h-auto object-cover"
                />
            </div>
        </div>



            <div className="bg-gradient-to-r from-red-500 via-purple-400 to-blue-500 mt-16 bg-[#f5f5f5]">
                <div className="max-w-[85rem] px-4 py-4 sm:px-6 lg:px-8 mx-auto">
                    {/* Grid */}
                    <div className="grid justify-center md:grid-cols-2 md:justify-between md:items-center gap-2">
                        <div className="text-center md:text-start">
                            <p className="text-xs text-white/80 uppercase tracking-wider">
                                Join us for an exciting future!
                            </p>
                            <p className="mt-1 text-white font-medium">
                                Join APNA today to connect with industry leaders, gain valuable support, and help shape the future of Pakistan's network infrastructure.
                            </p>
                        </div>
                        {/* End Col */}
                        <div className="mt-3 text-center md:text-start md:flex md:justify-end md:items-center">
                            <a
                                className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                                href="#"
                            >
                                Sign up free
                            </a>
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Grid */}
                </div>
            </div>

       

        <section className="text-gray-600 body-font bg-[#f5f5f5] mt-20">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center px-4">
                    <h2 className="text-2xl lg:text-3xl font-bold mb-4 text-blue-600">Our Vision</h2>
                    <p className="mb-8 text-gray-800">
                        APNA brings together ISPs and network operators from diverse backgrounds, including various religions, nationalities, languages, and professions. This assortment strengthens our association, enabling us to address a wide range of challenges and opportunities in the network sector.
                    </p>
                    <h2 className="text-2xl lg:text-3xl font-bold mb-4 text-blue-600">Commitment to Excellence</h2>
                    <p className="mb-8 text-gray-800">
                        At APNA, we are committed to achieving excellence in our industry. Through unity, innovation, and relentless advocacy, we strive to create a secure, equitable, and profitable environment for all ISPs and network operators. Our goal is to ensure that connectivity is seamless, secure, and accessible to everyone in Pakistan.
                    </p>
                    <p className="mb-8 text-gray-800">
                        APNA is an association that aims to drive sustainable development and unlock the full potential of Pakistan's telecommunications industry through strategic partnerships, advocacy initiatives, and innovative solutions. The association focuses on leveraging technology for social good, economic empowerment, and nation-building. APNA is committed to building a brighter, more connected future for all Pakistanis.
                    </p>
                </div>
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <img
                        className="object-cover object-center rounded"
                        alt="hero"
                        src="https://images.unsplash.com/photo-1689799514696-b16af9b53753?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />
                </div>
            </div>
        </section>

    </>
    )
}
