
import {React, useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


const root = ReactDOM.createRoot(document.getElementById('root'));
       

root.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>
    // <Router>
    //   <Routes>
    //     <Route path="/*" element={<App />} />
    //     <Route path="/admin/*" element={<Admin />} />
    //     <Route path="/admin/membership-board" element={<Membership_Board />} />
    //     <Route path='/admin/request-dashboard/*' element={<Req_Dashboard />} />
    //     <Route path='/admin/add_request/*' element={<Add_Req />} />
    //     <Route path='/admin/transfer_request/*' element={<Transfer_Req />} />
    //     <Route path='/admin/mem-roles/*' element={<Memb_Role />} />
    //     <Route path='/admin/members/*' element={<Members />} />
    //     <Route path='/admin/committee/*' element={<Committee />} />
    //     <Route path='/admin/exe-committes/*' element={<Exe_Committee />} />
    //     <Route path='/admin/core-committes/*' element={<Core_Committee />} />
    //     <Route path='/admin/adv-Board/*' element={<Adv_Board />} />
    //     <Route path='/admin/mangement/*' element={<Mangement />} />
    //     <Route path='/admin/profile' element={<Edit />} />
    //   </Routes>
    // </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();















    {/* <App /> */}
      {/* <Nav /> */}
      {/* <Sidebar /> */}
      {/* <Routes>
          <Route path="/*" element={<App />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/admin/sidebar/*" element={<Sidebar />} />
          <Route path='/sidebar/request-dashboard/*' element = { <Req_Dashboard/> } />
          <Route path='/sidebar/add_request/*' element = { <Add_Req/> } />
          <Route path='/sidebar/transfer_request/*' element = { <Transfer_Req/> } />
          <Route path='/sidebar/mem-roles/*' element = { <Memb_Role/> } />
          <Route path='/sidebar/members/*' element = { <Members/> } />
          <Route path='/sidebar/committee/*' element = { <Committee/> } />
          <Route path='/sidebar/exe-committes/*' element = { <Exe_Committee /> } />
          <Route path='/sidebar/core-committes/*' element = { <Core_Committee /> } />
          <Route path='/sidebar/adv-Board/*' element = { <Adv_Board /> } />
          <Route path='/sidebar/mangement/*' element = { <Mangement /> } />
        </Routes>  */}




