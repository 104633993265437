import { useState } from "react";
// import { Header } from "./Comp/Header/Header";
import { Header } from './Comp/Header/Header'
import { Routes, Route, } from 'react-router-dom'
import { Board } from './Comp/Nav_Icons/Board/Board'
import { Membership } from './Comp/Nav_Icons/Membership/Membership'
import { Menifesto } from './Comp/Nav_Icons/Menifesto/Menifesto'
import { Events } from './Comp/Nav_Icons/Events/Events'
import { Fruad } from './Comp/Nav_Icons/Fruad/Fruad'
import { Web_Committee } from "./Comp/Nav_Icons/Committee/Web_Committee";
import { Footer } from "./Comp/Footer/Footer";
import { Home } from "./Comp/Home/Home";
import { Login } from "./Comp/Nav_Icons/Account/Login";
import { Register } from "./Comp/Nav_Icons/Account/Register"
import { About } from "./Comp/Footer-icons/About";
import { Contact } from "./Comp/Footer-icons/Contact";
import { Disclaimer } from "./Comp/Footer-icons/Disclaimer";
import { Pricing } from "./Comp/Nav_Icons/Membership/Pricing";
import { Members } from "./Comp/Nav_Icons/Members/Members";
import { Switcher } from "./Comp/Switcher/Switcher";
import { BrowserRouter as Router } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import "preline/preline";
import { IStaticMethods } from "preline/preline";
import { Privacy } from "./Comp/Footer-icons/Privacy";
// import { Admin } from "./Admin/Admin";
import { Admin } from './Comp/Admin/Admin'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Req_Dashboard } from "./Comp/Admin/Sidebar/Request/Req_Dashboard";
import { Add_Req } from "./Comp/Admin/Sidebar/Request/Add_Req";
import { Transfer_Req } from "./Comp/Admin/Sidebar/Request/Transfer_Req";
import { Memb_Role } from "./Comp/Admin/Sidebar/Members/Memb_Role";
import { Exe_Committee } from "./Comp/Admin/Sidebar/Exe-Committee/ExeCommittee";
import { Core_Committee } from "./Comp/Admin/Sidebar/Core-Committee/Core_Committee";
import { Adv_Board } from "./Comp/Admin/Sidebar/Adv-Board/Adv_Board";
import { Mangement } from "./Comp/Admin/Sidebar/Request/Mangement";
import { Edit } from "./Comp/Admin/Edit Profile/Edit";
import { Committee } from './Comp/Admin/Sidebar/Members/Committee'
import { Admin_Members } from "./Comp/Admin/Sidebar/Members/Admin_Members";
import { Order_History } from "./Comp/Admin/Sidebar/Order-History/Order_History";
import ProtectedRoute from "./Comp/Admin/Protected/Protected_Route";
import Scrol_top from "./Comp/Scroll/Scrol_top";
if (typeof window !== 'undefined') {
  window.HSStaticMethods = {
    autoInit: function () {
    }
  };
}

function App() {
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  const [Profile, setProfile] = useState([])
  // console.log(Profile);

  useEffect(() => {
    axios.get('https://apna.org.pk/apna.org.pk/api/user')
      .then((res) => {
        const users = res.data.user;

        if (Array.isArray(users)) {
          const userData = users.map((item) => ({
            Profile: item.profile,
            Email: item.email,
          }));
          setProfile(userData);
        } else {
          console.error("User data is not an array");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  


  // Check if the current route contains 'admin'
  const isAdminRoute = location.pathname.startsWith('/admin');
  return (
    <>
      <Header />
      <Scrol_top />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menifesto" element={<Menifesto />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/events" element={<Events />} />
        <Route path="/fruad" element={<Fruad />} />
        <Route path="/web-committee" element={<Web_Committee />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/members" element={<Members />} />
        <Route path="/board" element={<Board />} />

        {/* ADMIN ROUTES */}
        <Route element={<ProtectedRoute />}>
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/admin/request-dashboard/*" element={<Req_Dashboard />} />
          <Route path="/admin/add_request/*" element={<Add_Req />} />
          <Route path="/admin/transfer_request/*" element={<Transfer_Req />} />
          <Route path="/admin/mem-roles/*" element={<Memb_Role />} />
          <Route path="/admin/admin-members/*" element={<Admin_Members />} />
          <Route path="/admin/committee/*" element={<Committee />} />
          <Route path="/admin/exe-committes/*" element={<Exe_Committee />} />
          <Route path="/admin/core-committes/*" element={<Core_Committee />} />
          <Route path="/admin/adv-Board/*" element={<Adv_Board />} />
          <Route path="/admin/mangement/*" element={<Mangement />} />
          <Route path="/admin/profile" element={<Edit />} />
          <Route path="/admin/order-history" element={<Order_History />} />
        </Route>
      </Routes>

      {/* Render Footer only if not on admin routes */}
      {!isAdminRoute && <Footer />}
    </>
  );
}

export default App;





























// <Route path="/*" element={<App />} />
// <Route path="/admin/*" element={<Admin />} />
// <Route path="/admin/sidebar/*" element={<Sidebar />} />
// {/* <Route path='/sidebar/advisory-board/*' element = { <Advisory_Board /> } /> */}
// <Route path='/sidebar/request-dashboard/*' element = { <Req_Dashboard/> } />
// <Route path='/sidebar/add_request/*' element = { <Add_Req/> } />
// <Route path='/sidebar/transfer_request/*' element = { <Transfer_Req/> } />
// <Route path='/sidebar/mem-roles/*' element = { <Memb_Role/> } />
// <Route path='/sidebar/members/*' element = { <Members/> } />
// <Route path='/sidebar/committee/*' element = { <Committee/> } />
// {/* <Route path='/sidebar/membership-committes/*' element = { <Membership_Committe /> } /> */}
// <Route path='/sidebar/exe-committes/*' element = { <Exe_Committee /> } />
// <Route path='/sidebar/core-committes/*' element = { <Core_Committee /> } />
// <Route path='/sidebar/adv-Board/*' element = { <Adv_Board /> } />
// <Route path='/sidebar/mangement/*' element = { <Mangement /> } />