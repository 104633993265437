import React from 'react'
import { Sidebar } from '../Sidebar'

export function Transfer_Req() {
    return (<>
        <Sidebar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">

            </div>
        </div>
    </>
    )
}
