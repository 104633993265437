import React from 'react'
import { useState, useEffect } from 'react';

export function Events() {

  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    'https://via.placeholder.com/600x400',
    'https://via.placeholder.com/600x400',
    'https://via.placeholder.com/600x400',
    'https://via.placeholder.com/600x400',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000); // 3 seconds interval for sliding

    return () => clearInterval(interval);
  }, [currentIndex]);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  return (<>
    <>
      <section className='bg-[#f5f5f5]'>
        <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:h-screen lg:grid-cols-2">
            <div className="relative z-10 lg:py-16 shadow-pink-800/10">
              <div className="relative h-64 sm:h-80 lg:h-full">
                <img
                  alt=""
                  src="https://images.unsplash.com/photo-1714255288526-cc155b548aac?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  className="absolute inset-0 h-full w-full object-cover rounded-xl border-pink-800/10 shadow-pink-800/10 shadow-xl transition"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="text-center p-4  bg-opacity-50 rounded-lg shadow-lg">
                    <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white">
                      OUR EVENTS
                    </h1>
                    <p className="mt-2 text-sm sm:text-base lg:text-lg text-white">
                      Discover the future of telecommunications at APNA's upcoming event. Join industry leaders for insightful discussions, networking, and cutting-edge technology showcases. Whether you're a professional or entrepreneur, this event offers invaluable insights and collaboration opportunities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative flex items-center bg-[#f5f5f5] block rounded-xl  "
              style={{
                border: '1px solid #3bc3ef' 
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
            >
              <span
                className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16"
              ></span>
              <div className="relative w-full max-w-md mx-auto">
                <div className="relative overflow-hidden rounded-lg">
                  <div className="flex transition-transform ease-out duration-500" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>

                    <div className="min-w-full h-64 flex-shrink-0 flex justify-center">
                      <a href="#" className="group relative block bg-black w-72 h-96">
                        <img
                          alt="Developer"
                          src="/media/Events/event4.jpg"
                          className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
                        />

                        <div className="relative p-4 sm:p-6 lg:p-8 h-full flex flex-col justify-between">
                          <div>
                            <p className="text-sm font-medium uppercase tracking-widest text-pink-500">ISP Summit</p>
                            <p className="text-xl font-bold text-white sm:text-2xl">Salman Ahmad</p>
                          </div>

                          <div className="mt-auto">
                            <div
                              className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                            >
                              <p className="text-sm text-white">
                                Join top ISPs from around the world to discuss emerging trends, regulatory challenges, and
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="min-w-full h-64 flex-shrink-0 flex justify-center">
                      <a href="#" className="group relative block bg-black w-72 h-96">
                        <img
                          alt="Developer"
                          src="/media/Events/event1.jpg"
                          className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
                        />
                        <div className="relative p-4 sm:p-6 lg:p-8 h-full flex flex-col justify-between">
                          <div>
                            <p className="text-sm font-medium uppercase tracking-widest text-pink-500">Global Networking Forum 2024</p>
                            <p className="text-xl font-bold text-white sm:text-2xl">Salman Ahmad</p>
                          </div>

                          <div className="mt-auto">
                            <div
                              className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                            >
                              <p className="text-sm text-white">
                                A flagship event for networking professionals, featuring keynote speeches from industry pioneers, hands-on workshops, and
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="min-w-full h-64 flex-shrink-0 flex justify-center">

                      <a href="#" className="group relative block bg-black w-72 h-96">
                        <img
                          alt="Developer"
                          src="/media/Events/event2.jpg"
                          className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
                        />

                        <div className="relative p-4 sm:p-6 lg:p-8 h-full flex flex-col justify-between">
                          <div>
                            <p className="text-sm font-medium uppercase tracking-widest text-pink-500">Next-Gen Networking Expo</p>
                            <p className="text-xl font-bold text-white sm:text-2xl">Salman Ahmad</p>
                          </div>

                          <div className="mt-auto">
                            <div
                              className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                            >
                              <p className="text-sm text-white">
                                An expo dedicated to the next generation of networking solutions, including 5G, SD-WAN, and edge
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="min-w-full  flex-shrink-0 flex justify-center">
                      <a href="#" className="group relative block bg-black w-72 h-96">
                        <img
                          alt="Developer"
                          src="/media/Events/event3.jpg"
                          className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
                        />
                        <div className="relative p-4 sm:p-6 lg:p-8 h-full flex flex-col justify-between">
                          <div>
                            <p className="text-sm font-medium uppercase tracking-widest text-pink-500">ISP Leadership Conference</p>
                            <p className="text-xl font-bold text-white sm:text-2xl">Salman Ahmad</p>
                          </div>

                          <div className="mt-auto">
                            <div
                              className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                            >
                              <p className="text-sm text-white">
                                A strategic conference for ISP executives and decision-makers. Focus on business growth, customer experience enhancement,
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <button onClick={goToPrevious} className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full">
                  &lt;
                </button>
                <button onClick={goToNext} className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full">
                  &gt;
               </button>
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                  <button onClick={() => goToSlide(0)} className={`w-3 h-3 rounded-full ${currentIndex === 0 ? 'bg-white' : 'bg-gray-400'}`} />
                  <button onClick={() => goToSlide(1)} className={`w-3 h-3 rounded-full ${currentIndex === 1 ? 'bg-white' : 'bg-gray-400'}`} />
                  <button onClick={() => goToSlide(2)} className={`w-3 h-3 rounded-full ${currentIndex === 2 ? 'bg-white' : 'bg-gray-400'}`} />
                  <button onClick={() => goToSlide(3)} className={`w-3 h-3 rounded-full ${currentIndex === 3 ? 'bg-white' : 'bg-gray-400'}`} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  </>
  )
}


