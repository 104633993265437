
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Sidebar } from '../Sidebar';
import axios from 'axios';
import Complete_Profile from '../../Complete Profile/Complete_Profile';
export function Memb_Role() {

    const { register } = useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [roleId, setRoleId] = useState(null);
    const [roleName, setRoleName] = useState('');
    const [switches, setSwitches] = useState({
        Members: false,
        Committees: false,
    });
    const [checkboxValues, setCheckboxValues] = useState({
        Members: [],
        Committees: [],
    });

    const openModal = (role = null) => {
        if (role) {
            setRoleId(role.id);
            setRoleName(role.roleName);
            setCheckboxValues({
                Members: [
                    role.Addnewmember && 'Add new member',
                    role.Approve && 'Approve',
                    role.Reject && 'Reject'
                ].filter(Boolean),
                Committees: [
                    role.CreateCommittees && 'Create Committees',
                    role.Info && 'Info',
                    role.Edit && 'Edit',
                    role.Delete && 'Delete'
                ].filter(Boolean),
            });
            setSwitches({
                Members: true,
                Committees: true,
            });
            setIsEditMode(true);
        } else {
            resetForm();
        }
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        resetForm();
    };

    const resetForm = () => {
        setRoleId(null);
        setRoleName('');
        setSwitches({
            Members: false,
            Committees: false,
        });
        setCheckboxValues({
            Members: [],
            Committees: [],
        });
        setIsEditMode(false);
    };

    const handleSwitchChange = (committee) => {
        setSwitches((prev) => {
            const updatedSwitches = { ...prev, [committee]: !prev[committee] };
            if (!updatedSwitches[committee]) {
                setCheckboxValues((prevValues) => ({
                    ...prevValues,
                    [committee]: [],
                }));
            }
            return updatedSwitches;
        });
    };

    const handleCheckboxChange = (committee, checkboxLabel, checked) => {
        setCheckboxValues((prevValues) => {
            const updatedValues = { ...prevValues };
            if (checked) {
                updatedValues[committee] = [...(updatedValues[committee] || []), checkboxLabel];
            } else {
                updatedValues[committee] = (updatedValues[committee] || []).filter(
                    (value) => value !== checkboxLabel
                );
            }
            return updatedValues;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const submissionData = {
            roleName,
            Addnewmember: checkboxValues.Members.includes('Add new member') ? 1 : 0,
            Approve: checkboxValues.Members.includes('Approve') ? 1 : 0,
            Reject: checkboxValues.Members.includes('Reject') ? 1 : 0,
            Members: checkboxValues.Members.length > 0 ? 1 : 0,
            CreateCommittees: checkboxValues.Committees.includes('Create Committees') ? 1 : 0,
            Info: checkboxValues.Committees.includes('Info') ? 1 : 0,
            Edit: checkboxValues.Committees.includes('Edit') ? 1 : 0,
            Delete: checkboxValues.Committees.includes('Delete') ? 1 : 0,
            Committees: checkboxValues.Committees.length > 0 ? 1 : 0,
        };

        try {
            if (isEditMode) {
                await axios.put(`https://apna.org.pk/apna.org.pk/api/updaterole/${roleId}`, submissionData);
                console.log("Role updated successfully.");
            } else {
                await axios.post('https://apna.org.pk/apna.org.pk/api/create', submissionData);
                console.log("Role created successfully.");
            }
        } catch (error) {
            console.error("Error sending data:", error);
        }

        resetForm();
        closeModal();
    };

    const [Data, setData] = useState([]);

    useEffect(() => {
        axios.get("https://apna.org.pk/apna.org.pk/api/getrole").then((res) => {
            setData(res.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this role?");
        if (confirmDelete) {
            try {
                await axios.delete(`https://apna.org.pk/apna.org.pk/api/deleterole/${id}`);
                setData(Data.filter((role) => role.id !== id));
                console.log("Role deleted successfully.");
            } catch (error) {
                console.error("Error deleting role:", error);
            }
        }
    };

    const switchesConfig = {
        Members: [
            { label: 'Member Roles', checkboxes: ['Add new member', 'Approve', 'Reject'] },
        ],
        Committees: [
            { label: 'Committees Role', checkboxes: ['Create Committees', 'Info', 'Edit', 'Delete'] },
        ],
    };


    const [authorized, setauthorized] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('token');
    const postData = async () => {
        try {
            const response = await axios.post(
                'https://apna.org.pk/apna.org.pk/api/check-token', 
                {
                    first_name: "",
                    email: '',
                },
                {
                   
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                        'Content-Type': 'application/json', 
                    },
                }
            );

            if (response.data.message) {
                setauthorized(response.data.user);
            }
            //   console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error.response);
        }
    };
 
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);  

            await postData();

            axios.get('https://apna.org.pk/apna.org.pk/api/advisiory')
                .then((res) => {
                    setData(res.data.user); 
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);  // Stop loading once data is fetched
                });
        };

        fetchData();
    }, []);



    if (isLoading) {     
        return (
            <>
                <Sidebar />
                <div className="p-4 sm:ml-64 flex justify-center items-center h-screen">
                    <div role="status">
                        <svg
                            aria-hidden="true"
                            className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
                </div>

            </>
        );
    }

    return (
        <>
            <Sidebar />
            <div className="p-4 sm:ml-64 mt-3">
                { authorized?.profile !== 'uncomplete' ?
                    <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 ">
                        <h1 className="text-center text-blue-600 text-2xl font-semibold">Member Roles</h1>
                        <div className="flex flex-wrap justify-end mt-4">
                            <button
                                onClick={() => openModal()}
                                className="bg-blue-500 text-white font-bold py-2 px-4 rounded mr-2 mb-2 hover:bg-blue-600 transition-colors"
                            >
                                Add New Role
                            </button>

                            {isModalOpen && (
                                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full max-h-[80vh] overflow-y-auto">
                                        <h2 className="text-xl font-bold mb-4 text-center">{isEditMode ? 'Edit Role' : 'Add New Role'}</h2>

                                        <form onSubmit={handleSubmit}>
                                            <input
                                                type="text"
                                                value={roleName}
                                                onChange={(e) => setRoleName(e.target.value)}
                                                placeholder="Enter Role Name"
                                                className="border rounded w-full py-2 px-3 mb-4"
                                            />

                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                {Object.keys(switchesConfig).map((committee) => (
                                                    <div key={committee} className="mb-4">
                                                        <div className="flex items-center mb-2">
                                                            <input
                                                                type="checkbox"
                                                                id={`switch-${committee}`}
                                                                checked={switches[committee]}
                                                                onChange={() => handleSwitchChange(committee)}
                                                                className="relative w-11 h-6 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
                                                            />
                                                            <label htmlFor={`switch-${committee}`} className="text-sm text-gray-500 ms-3 capitalize">{committee}</label>
                                                        </div>

                                                        {switches[committee] && (
                                                            <div className="ml-6">
                                                                {switchesConfig[committee].map((config, index) => (
                                                                    <div key={index} className="mb-4">
                                                                        <div className="font-semibold mb-2">{config.label}</div>
                                                                        {config.checkboxes.map((checkboxLabel, nestedIndex) => (
                                                                            <label key={nestedIndex} className="flex items-center mb-2">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={checkboxValues[committee].includes(checkboxLabel)}
                                                                                    onChange={(e) => handleCheckboxChange(committee, checkboxLabel, e.target.checked)}
                                                                                    className="mr-2"
                                                                                />
                                                                                {checkboxLabel}
                                                                            </label>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="flex justify-end mt-6">
                                                <button
                                                    type="button"
                                                    onClick={closeModal}
                                                    className="bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2 hover:bg-gray-600 transition-colors"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition-colors"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>

                        <h1 className='text-center text-blue-600 text-3xl font-semibold'>Admin Roles</h1>
                        <div className="flex flex-col mt-10">
                            <div className="-m-1.5 overflow-x-auto">
                                <div className="p-1.5 min-w-full inline-block align-middle">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                                            <thead>
                                                <tr className='odd:bg-gray-100 even:bg-gray-500 hover:bg-gray-100 dark:odd:bg-neutral-800 dark:even:bg-neutral-700 dark:hover:bg-neutral-700'>
                                                    <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                                        Role Name
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Data.map((roles) => (
                                                    <tr key={roles.id} className="odd:bg-white even:bg-gray-100 hover:bg-gray-100 dark:odd:bg-neutral-800 dark:even:bg-neutral-700 dark:hover:bg-neutral-700">
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">
                                                            {roles.roleName}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium m-5">
                                                            <div className="inline-flex space-x-4">
                                                                <button
                                                                    type="button"
                                                                    className="text-blue-600 hover:text-blue-800"
                                                                    onClick={() => openModal(roles)}
                                                                >
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="text-blue-600 hover:text-blue-800"
                                                                    onClick={() => handleDelete(roles.id)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Complete_Profile />
                }
            </div>
        </>
    );
}


























