import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Form_Modal } from './Form-Modal';
import('https://cdn.jsdelivr.net/npm/preline/dist/preline.js');
export function Header() {
  useEffect(() => {
    import('https://cdn.jsdelivr.net/npm/preline/dist/preline.js');
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };



  const { register, handleSubmit, reset } = useForm();
  function Submit(data) {
    console.log("Posting data...", data.type);

    const formData = new FormData();

    if (data.file && data.file.length > 0) {
      formData.append('file', data.file[0]);
    }

    Object.keys(data).forEach(key => {
      if (key !== 'file') {
        formData.append(key, data[key]);
      }
    });

    for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    axios.post('https://apna.org.pk/apna.org.pk/api/register', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        console.log("Data posted successfully");
        reset();
      })
      .catch((error) => {
        console.log(error);
        console.error("Error posting data:", error.response ? error.response.data : error.message);
      });
    // console.log(formData);

  }
  const [isRegOpen, setRegIsOpen] = useState(false);






  // const { register, handleSubmit, reset } = useForm();
  function Submit(data) {
    console.log("Posting data...", data.type);

    const formData = new FormData();

    if (data.file && data.file.length > 0) {
      formData.append('file', data.file[0]);
    }

    Object.keys(data).forEach(key => {
      if (key !== 'file') {
        formData.append(key, data[key]);
      }
    });

    for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    axios.post('https://apna.org.pk/apna.org.pk/api/register', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        console.log("Data posted successfully");
        reset();
      })
      .catch((error) => {
        console.log(error);
        console.error("Error posting data:", error.response ? error.response.data : error.message);
      });
    // console.log(formData);

  }

  const [type, setType] = useState("");
  const handleRadioChange = e => {
    setType(e.target.value);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted!");
    setIsModalOpen(false); // Close modal after submission
  };

  return (
    <>
      <header className="flex bg-white sticky top-0 header-bg flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full text-sm py-2 sm:py-0 dark:bg-neutral-800 dark:border-neutral-700
            
            ">
        <nav
          className="relative max-w-[85rem] bg-white flex flex-wrap basis-full items-center w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex items-center justify-between">
            <Link to={'/'} >
              <img
                src="/media/logo/logo.png"
                className="mr-3 h-10 w-auto sm:h-12 md:h-16 lg:h-20 xl:h-16"
                alt="Logo"
              />
            </Link>
          </div>
          <div className="flex items-center ms-auto sm:ms-0 sm:order-3">
            <div className="sm:hidden">
              <button
                type="button"
                className="p-2 inline-flex justify-center items-center gap-2 rounded-lg border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-xs dark:bg-neutral-800 dark:hover:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                onClick={toggleMenu}
                aria-label="Toggle navigation"
              >
                Menu
                <svg
                  className={`${isMenuOpen ? 'hidden' : 'block'} size-4`}
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx={12} cy={12} r={1} />
                  <circle cx={12} cy={5} r={1} />
                  <circle cx={12} cy={19} r={1} />
                </svg>
              </button>
            </div>
          </div>
          <div
            id="navbar-offcanvas-example"
            className={`${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} fixed top-0 start-0 transition-all duration-300 transform h-full max-w-xs w-full z-[60] basis-full grow sm:order-2 sm:static sm:block sm:h-auto sm:max-w-none sm:w-auto sm:border-r-transparent sm:transition-none sm:translate-x-0 sm:z-40 sm:basis-auto dark:bg-neutral-800 dark:border-r-gray-700 sm:dark:border-r-transparent`}
          >
            <div className="flex flex-col bg-white sm:flex-row sm:items-center sm:justify-end py-2 md:py-0 sm:ps-7">
              <Link
                to={'board'}
                className="px-2 py-2 sm:px-3 font-medium text-[#7c3aed] dark:text-neutral-400  transition duration-500 ease-in-out hover:text-white hover:bg-[#7c3aed] rounded-full"
              >
                Advisory Board
              </Link>
              <Link
                to={'web-committee'}
                className="px-2 py-2 sm:px-3 font-medium text-blue-600 dark:text-neutral-400 rounded-full transition duration-500 ease-in-out hover:text-white hover:bg-[#7c3aed]"
              >
                Committee
              </Link>
              <Link
                to={'members'}
                className="px-2 py-2 sm:px-3 font-medium text-blue-600 dark:text-neutral-400  transition duration-500 ease-in-out hover:text-white hover:bg-[#7c3aed] rounded-full"
              >
                Members
              </Link>
              <Link
                to={'menifesto'}
                className="px-2 py-2 sm:px-3 font-medium text-blue-600 dark:text-neutral-400  transition duration-500 ease-in-out hover:text-white hover:bg-[#7c3aed] rounded-full"
              >
                Menifesto
              </Link>
              <Link
                to={'membership'}
                className="px-2 py-2 sm:px-3 font-medium text-blue-600 dark:text-neutral-400 rounded-full transition duration-500 ease-in-out hover:text-white hover:bg-[#7c3aed] "
              >
                Membership
              </Link>
              <Link
                to={'events'}
                className="px-2 py-2 sm:px-3 font-medium text-blue-600 dark:text-neutral-400 rounded-full transition duration-500 ease-in-out hover:text-white hover:bg-[#7c3aed]"
              >
                Events
              </Link>
              <Link
                to={'fruad'}
                className="px-2 py-2 sm:px-3 font-medium text-blue-600 dark:text-neutral-400 rounded-full transition duration-500 ease-in-out hover:text-white hover:bg-[#7c3aed]"
              >
                Fruads
              </Link>
              <div className="flex space-x-2 lg:space-x-3">
                <Link to={'login'} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-full transition-all duration-300 ease-in-out sm:py-2 sm:px-4 md:py-3 md:px-5 lg:py-2 lg:px-4">
                  Login
                </Link>



                <Link
                  // onClick={() => setIsModalOpen(true)}
                  to={'register'}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-3 rounded-full transition-all duration-300 ease-in-out sm:py-2 sm:px-4 md:py-3 md:px-5 lg:py-2 lg:px-4"
                >
                  Register

                </Link>




              </div>
            </div>
          </div>
        </nav >
      </header >

      <div
        id="navbar-secondary-content"
        className={`${isDropdownOpen ? 'translate-x-0' : '-translate-x-full'} fixed top-0 start-0 transition-all duration-300 transform h-full max-w-xs w-full z-[80] bg-white border-e dark:bg-neutral-800 dark:border-neutral-700`}
        tabIndex={-1}
      >
        <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
          <h3 className="font-bold text-gray-800 dark:text-white">Offcanvas title</h3>
          <button
            type="button"
            className="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-lg text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white text-sm dark:text-neutral-500 dark:hover:text-neutral-400 dark:focus:ring-neutral-700 dark:focus:ring-offset-gray-800"
            onClick={toggleDropdown}
          >
            <span className="sr-only">Close offcanvas</span>
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
        </div>
        <div className="p-4">
          <p className="text-gray-800 dark:text-white">This is your dropdown content.</p>
        </div>
      </div>
    </>
  );
}