import React from 'react'
import { Sidebar } from '../Sidebar'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Complete_Profile from '../../Complete Profile/Complete_Profile'
export function Exe_Committee() {

    const [Data, setData] = useState([])


    useEffect(() => {
        axios.get("https://apna.org.pk/apna.org.pk/api/executive").then((res) => {
            setData(res.data.user);

        }).catch((err) => {
            console.log(err);
        })
    }, [Data])

    const [currentUserId, setCurrentUserId] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const { register, handleSubmit, reset } = useForm();

    const togglePopup = (userId) => {
        setCurrentUserId(userId);
        setIsPopupVisible(!isPopupVisible);
        reset();
    };

    const onSubmit = async (formData) => {
        const dataToSubmit = {
            ...formData,
            id: currentUserId,
        };

        console.log("dts>>>", dataToSubmit);

        try {
            const response = await axios.post(
                'https://apna.org.pk/apna.org.pk/api/acceptexc',
                dataToSubmit
            );

            console.log('API Response:', response.data);

            setIsPopupVisible(false);
            setSubmitted(true);

            reset();
        }

        catch (error) {
            console.error('Error submitting form:', error.response ? error.response.data : error.message);
            alert('Failed to submit form. Please try again.');
        }
    };

    React.useEffect(() => {
        if (submitted) {
            console.log('Form has been submitted, component will re-render.');
            setSubmitted(false);
        }
    }, [submitted]);


    const [RejectcurrentUserId, setRejectCurrentUserId] = useState(null);
    const [isRejectPopupVisible, setIsRejectPopupVisible] = useState(false);
    const [Rejectsubmitted, setRejectSubmitted] = useState(false);
    const toggleRejectPopup = (userId) => {
        setRejectCurrentUserId(userId);
        setIsRejectPopupVisible(!isPopupVisible);
        reset();
    };

    const onRejectSubmit = async (formData) => {
        const dataToSubmit = {
            ...formData,
            id: RejectcurrentUserId,

        };

        console.log(dataToSubmit);

        try {
            const response = await axios.post(
                'https://apna.org.pk/apna.org.pk/api/rejectexc',
                dataToSubmit
            );

            console.log('API Response:', response.data);

            setIsRejectPopupVisible(false);
            setRejectSubmitted(true);
            reset();
        }

        catch (error) {
            console.error('Error submitting form:', error.response ? error.response.data : error.message);
            alert('Failed to submit form. Please try again.');
        }
    };
    React.useEffect(() => {
        if (submitted) {
            console.log('Form has been submitted, component will re-render.');
            setRejectSubmitted(false);
        }
    }, [Rejectsubmitted]);



    const [authorized, setauthorized] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('token');
    const postData = async () => {
        try {
            const response = await axios.post(
                'https://apna.org.pk/apna.org.pk/api/check-token',  // Replace with your API endpoint
                {
                    // Request body (data you're sending)
                    first_name: "",
                    email: '',
                },
                {
                    // Headers with Authorization
                    headers: {
                        'Authorization': `Bearer ${token}`, // Send the Bearer Token in Authorization header
                        'Content-Type': 'application/json', // Set the content type to JSON
                    },
                }
            );

            // Handle the response from the server
            if (response.data.message) {
                setauthorized(response.data.user);
            }
            //   console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error.response);
        }
    };
 
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);  // Start loading

            await postData();  // Call postData

            axios.get('https://apna.org.pk/apna.org.pk/api/advisiory')
                .then((res) => {
                    setData(res.data.user);  // Set data from advisory API
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);  // Stop loading once data is fetched
                });
        };

        fetchData();
    }, []);



    if (isLoading) {     
        return (
            <>
                <Sidebar />
                <div className="p-4 sm:ml-64 flex justify-center items-center h-screen">
                    <div role="status">
                        <svg
                            aria-hidden="true"
                            className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
                </div>

            </>
        );
    }

    return (<>

        <Sidebar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 mt-3">
                {authorized?.profile !== 'uncomplete' ?

                    <section className="container px-4 mx-auto">
                        <h1 className='text-blue-600 text-center font-medium text-2xl'>Request Of Members</h1>
                        <div className="flex items-center gap-x-3">
                            <h2 className="text-lg font-medium text-gray-800 dark:text-white">
                                Team members
                            </h2>
                            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">
                                100 users
                            </span>
                        </div>
                        <div className="flex flex-col mt-6">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                            <thead className="bg-gray-50 dark:bg-gray-800">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                        <div className="flex items-center gap-x-3">

                                                            <span>Name</span>
                                                        </div>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                        <button className="flex items-center gap-x-2">
                                                            <span>Reason</span>
                                                            <svg
                                                                className="h-3"
                                                                viewBox="0 0 10 11"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                                                                    fill="currentColor"
                                                                    stroke="currentColor"
                                                                    strokeWidth="0.1"
                                                                />
                                                                <path
                                                                    d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                                                                    fill="currentColor"
                                                                    stroke="currentColor"
                                                                    strokeWidth="0.1"
                                                                />
                                                                <path
                                                                    d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                                                                    fill="currentColor"
                                                                    stroke="currentColor"
                                                                    strokeWidth="0.3"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                        <button className="flex items-center gap-x-2">
                                                            <span>Email adress</span>
                                                            {/* <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            className="w-4 h-4"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                                                            />
                                                        </svg> */}
                                                        </button>
                                                    </th>
                                                    {/* <th
                                                    scope="col"
                                                    className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                >
                                                    Email address
                                                </th> */}
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                        Approvels
                                                    </th>
                                                    <th scope="col" className="relative py-3.5 px-4">
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {Data.map((users) => {
                                                return (<>
                                                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                                        <tr>
                                                            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                                                <div className="inline-flex items-center gap-x-3">
                                                                    <div className="flex items-center gap-x-2">
                                                                        <img
                                                                            className="object-cover w-10 h-10 rounded-full"
                                                                            src={users.file}
                                                                            alt=""
                                                                        />
                                                                        <div>
                                                                            <h2 className="font-medium text-gray-800 dark:text-white ">
                                                                                {users.first_name}
                                                                            </h2>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                                                <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                                                                    <span className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                                                    <h2 className="text-sm font-normal text-emerald-500">
                                                                        {users.reason}
                                                                    </h2>
                                                                </div>
                                                            </td>
                                                            {/* <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                    Design Director
                                                </td> */}
                                                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                                {users.email}
                                                            </td>
                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                <div className="flex items-center gap-x-2">

                                                                    {/* <p className="px-3 py-1 text-xs text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">
                                                            Approve
                                                        </p> */}
                                                                    <button
                                                                        className="px-3 py-1 text-xs text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60"
                                                                        onClick={() => togglePopup(users.id)} // Pass the user's ID to the toggle function
                                                                    >
                                                                        Approve
                                                                    </button>
                                                                    <button
                                                                        className="px-3 py-1 text-xs text-pink-500 rounded-full dark:bg-gray-800 bg-pink-100/60"
                                                                        onClick={() => toggleRejectPopup(users.id)}
                                                                    // Example user ID, replace with actual
                                                                    >
                                                                        Reject
                                                                    </button>

                                                                </div>
                                                            </td>
                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </>
                                                )
                                            })}

                                            {isPopupVisible && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                                    <div className="bg-white p-6 rounded shadow-lg w-1/3">
                                                        <h2 className="text-lg font-bold mb-4">Form</h2>
                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reason">
                                                                    Reason
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="reason"
                                                                    type="text"
                                                                    {...register('reason', { required: true })}
                                                                />
                                                            </div>

                                                            <div className="mb-4">

                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="userID"
                                                                    type="text"
                                                                    value={currentUserId} // Dynamically set the value from state
                                                                    readOnly // Make the field read-only
                                                                    hidden
                                                                />
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    type="text"
                                                                    value={'Core'}
                                                                    {...register('status')}
                                                                    readOnly
                                                                    hidden
                                                                />
                                                            </div>

                                                            <div className="flex items-center justify-between">
                                                                <button
                                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    type="submit"
                                                                >
                                                                    Submit
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    onClick={() => setIsPopupVisible(false)} // Close the popup
                                                                >
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}


                                            {isRejectPopupVisible && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                                    <div className="bg-white p-6 rounded shadow-lg w-1/3">
                                                        <h2 className="text-lg font-bold mb-4">Form</h2>
                                                        <form onSubmit={handleSubmit(onRejectSubmit)}>
                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reason">
                                                                    Reason
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="reason"
                                                                    type="text"
                                                                    {...register('reason', { required: true })}
                                                                />
                                                            </div>

                                                            <div className="mb-4">

                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="userID"
                                                                    type="text"
                                                                    // value={currentUserId} // Dynamically set the value from state
                                                                    value={RejectcurrentUserId}
                                                                    readOnly // Make the field read-only
                                                                    hidden
                                                                />
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    type="text"
                                                                    value={'reject'}
                                                                    {...register('status')}
                                                                    readOnly
                                                                    hidden
                                                                />
                                                            </div>

                                                            <div className="flex items-center justify-between">
                                                                <button
                                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    type="submit"
                                                                >
                                                                    Submit
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    onClick={() => setIsRejectPopupVisible(false)} // Close the popup
                                                                >
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}





                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                            <a
                                href="#"
                                className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 rtl:-scale-x-100"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                                    />
                                </svg>
                                <span>previous</span>
                            </a>
                            <div className="items-center hidden lg:flex gap-x-3">
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-blue-500 rounded-md dark:bg-gray-800 bg-blue-100/60"
                                >
                                    1
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    2
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    3
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    ...
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    12
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    13
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    14
                                </a>
                            </div>
                            <a
                                href="#"
                                className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
                            >
                                <span>Next</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 rtl:-scale-x-100"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                    />
                                </svg>
                            </a>
                        </div>
                    </section>
                    :
                    <Complete_Profile>

                    </Complete_Profile>
                }
            </div>
        </div>
    </>
    )
}
