import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Sidebar } from '../Sidebar';
import axios from 'axios';
import { useEffect } from 'react';
import Select from 'react-select'
import { Com_Table } from './Com_Table';
import { Nav } from '../../Nav/Nav';
import Complete_Profile from '../../Complete Profile/Complete_Profile';
export const Committee = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [committeeData, setCommitteeData] = useState({ name: '' });
    const [apiResponse, setApiResponse] = useState('');
    // console.log(apiResponse);

    const [error, setError] = useState('');
    const [data, setData] = useState([]);
    // console.log(data);
    // console.log(error);

    const { register, handleSubmit, reset } = useForm();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get('https://apna.org.pk/apna.org.pk/api/commite');
                setData(res.data.data);
            } catch (err) {
                console.error('Error fetching committee data:', err);
            }
        };
        fetchData();
    }, [data]);

    useEffect(() => {
        axios.get('https://apna.org.pk/apna.org.pk/api/commite')
            .then((res) => {
                setData(res.data.data);
            })
            .catch((err) => {
                console.log('Error fetching data:', err);
            });
    }, []);


    useEffect(() => {
        console.log('Committee data:', committeeData);
    }, [committeeData]);


    const onSubmit = async (data) => {
        setCommitteeData(data);

        try {
            const response = await axios.post('https://apna.org.pk/apna.org.pk/api/createcommitte', data);
            setApiResponse(response.data.message || 'Committee created successfully!');
            setError('');
        } catch (err) {
            console.error('Error response:', err.response);
            setError(err.response?.data?.message || 'An error occurred while creating the committee.');
            setApiResponse('');
        }

        closeModal();
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        reset();
    };


    //////// DELETE  ////////////////////


    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://apna.org.pk/apna.org.pk/api/deletecommite/${id}`);
            setData(data.filter(item => item.id !== id)); // Remove the item from the state
            setApiResponse('Committee deleted successfully!');
        } catch (err) {
            console.error('Error response:', err.response);
            setError(err.response?.data?.message || 'An error occurred while deleting the committee.');
            setApiResponse('');
        }
    };






    /////////////////EDIT////////////////////////////








    ///////////////////////INFO/////////////////////////

    const [isInfoOpenModal, setIsOpenModal] = useState(false)
    const [currentCommittee, setCurrentCommittee] = useState('');

    const InfoModal = (committeeName) => {
        setIsOpenModal(true)
        setCurrentCommittee(committeeName);
        console.log(data);


    }

    function onClose() {
        setIsOpenModal(false)
    }

    const [MembersData, setUserData] = useState([])

    useEffect(() => {
        axios.get('https://apna.org.pk/apna.org.pk/api/user').then((res) => {
            setUserData(res.data.user)
        }).catch((err) => {
            console.log(err);
        })
    }, [MembersData])


    const options = MembersData.map(user => ({
        value: user.email,
        label: user.first_name,
        ID: user.id
    }));


    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const handleChange = (selected) => {
        setSelectedOptions(selected);

        const ids = selected.map(option => option.ID);
        setSelectedIds(ids);

        console.log('Selected options:', selected);
        console.log('Selected IDs:', ids);
    };



    const [authorized, setauthorized] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('token');
    const postData = async () => {
        try {
            const response = await axios.post(
                'https://apna.org.pk/apna.org.pk/api/check-token', 
                {
                    first_name: "",
                    email: '',
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                        'Content-Type': 'application/json', 
                    },
                }
            );

            if (response.data.message) {
                setauthorized(response.data.user);
            }
        } catch (error) {
            console.error('Error:', error.response);
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);  

            await postData();  

            axios.get('https://apna.org.pk/apna.org.pk/api/advisiory')
                .then((res) => {
                    setData(res.data.user);  
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false); 
                });
        };

        fetchData();
    }, []);

    if (isLoading) {
        return (
            <>
                <Sidebar />
                <div className="p-4 sm:ml-64 flex justify-center items-center h-screen">
                    <div role="status">
                        <svg
                            aria-hidden="true"
                            className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
                </div>

            </>
        );
    }





    return (
        <>

            <Sidebar />
        
            <div className="p-4 mt-3 sm:ml-64">
                {authorized?.profile !== 'uncomplete' ?

                    <section className=" container px-4 mx-auto">
                        <div className="relative p-4">
                            <h1 className="text-center text-blue-600 text-2xl font-semibold">Committee</h1>
                            <button
                                onClick={openModal}
                                className="mt-16 absolute top-4 right-4 bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 sm:top-2 sm:right-2"
                            >
                                Create Committee
                            </button>
                        </div>

                        <div className="flex items-center gap-x-3">
                            <h2 className="text-lg font-medium text-gray-800 dark:text-white">
                                Team members
                            </h2>
                            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">
                                100 users
                            </span>
                        </div>
                        <div className="flex flex-col mt-6">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                            <thead className="bg-gray-50 dark:bg-gray-800">

                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                    >
                                                        <div className="flex items-center gap-x-3">

                                                            <span className='text-blue-600 text-xl font-semi'
                                                            >Committe name</span>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="relative py-3.5 px-4">
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                                <Com_Table />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                            <a
                                href="#"
                                className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 rtl:-scale-x-100"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                                    />
                                </svg>
                                <span>previous</span>
                            </a>
                            <div className="items-center hidden lg:flex gap-x-3">
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-blue-500 rounded-md dark:bg-gray-800 bg-blue-100/60"
                                >
                                    1
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    2
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    3
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    ...
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    12
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    13
                                </a>
                                <a
                                    href="#"
                                    className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
                                >
                                    14
                                </a>
                            </div>
                            <a
                                href="#"
                                className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
                            >
                                <span>Next</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 rtl:-scale-x-100"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                    />
                                </svg>
                            </a>
                        </div>
                    </section>

                    :
                    <Complete_Profile />
                }

                {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-white rounded-lg w-11/12 max-w-md p-6">
                            <h2 className="text-xl font-semibold mb-4">Enter Committee Name</h2>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input
                                    type="text"
                                    {...register('committe', { required: true })}
                                    className="w-full p-2 border border-gray-300 rounded mb-4"
                                    placeholder="Committee Name"
                                />
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2"
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    onClick={closeModal}
                                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                >
                                    Close
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </>

    );
};

