import React, { useState } from 'react';
export function Members() {
    const [showAll, setShowAll] = useState(false);
    //    " I am an ambitious workaholic, but apart from that, pretty simple person"
    const members = [
        { name: "David Forren", role: "Founder / CEO", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Amil Evara", role: "UI/UX Designer", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Ebele Egbuna", role: "Support Consultant", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Maria Powers", role: "Director of sales", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Delia Pawelke", role: "Front-end Developer", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Tom Lowry", role: "UI/UX Designer", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1624224971170-2f84fed5eb5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Louise Donadieu", role: "Support Consultant", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1579017331263-ef82f0bbc748?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Jeff Fisher", role: "Project Manager", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1514222709107-a180c68d72b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Sophia Harrington", role: "Project Manager", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1602452920335-6a132309c7c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Christina Kray", role: "Support Consultant", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1515621061946-eff1c2a352bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Amy Forren", role: "Product Designer", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1514846226882-28b324ef7f28?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Philip Williams", role: "Support Consultant", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Brian Lofoten", role: "UI/UX Designer", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1520409364224-63400afe26e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Jessica Dorsey", role: "Director of sales", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1558507652-2d9626c4e67a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
        { name: "Nick Jackson", role: "UI/UX Designer", con: 'I am an ambitious workhalic, but apart from that, pretty simple person', imgSrc: "https://images.unsplash.com/photo-1521151716396-b2da27b1a19f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" },
    ];

    const displayedMembers = showAll ? members : members.slice(0, 8);







    return (<>

        <>
            <div className="container mx-auto p-4 rounded-md mt-16 bg-[#f5f5f5]">
                <div className="max-w-1xl px-2 py-2 sm:px-6 lg:px-8 lg:py-3 mx-auto ">
                    <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
                        <h2 className="text-2xl font-bold md:text-4xl md:leading-tight text-blue-600">
                            Executive Members
                        </h2>
                        <p className="mt-1 text-gray-800">Creative people</p>
                    </div>
                    {/* <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-8 md:gap-12"> */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-12">
                        {displayedMembers.map((member, index) => (
                            // <div key={index} className="text-center">
                            //     <img
                            //         className="rounded-full size-24 mx-auto"
                            //         src={member.imgSrc}
                            //         alt="Avatar"
                            //     />
                            //     <div className="mt-2 sm:mt-4">
                            //         <h3 className="text-lg font-semibold">{member.name}</h3>
                            //         <p className="text-gray-600">{member.role}</p>
                            //     </div>
                            // </div>

                            <div className="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-gray-200 dark:bg-neutral-900 dark:border-neutral-700">
                                <div className="flex items-center gap-x-4">
                                    <img
                                        className="rounded-full size-20"
                                        src={member.imgSrc}
                                    />
                                    <div className="grow">
                                        <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                            {member.name}
                                        </h3>
                                        <p className="text-xs uppercase text-gray-500 dark:text-neutral-500">
                                            {member.role}
                                        </p>
                                    </div>
                                </div>
                                <p className="mt-3 text-gray-500 dark:text-neutral-500">
                                    {member.con}
                                </p>
                                {/* Social Brands */}
                                <div className="mt-3 space-x-1">
                                    <a
                                        className="inline-flex justify-center items-center size-8 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:border-neutral-700 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                        href="#"
                                    >
                                        <svg
                                            className="shrink-0 size-3.5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                        </svg>
                                    </a>
                                    <a
                                        className="inline-flex justify-center items-center size-8 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:border-neutral-700 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                        href="#"
                                    >
                                        <svg
                                            className="shrink-0 size-3.5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                                        </svg>
                                    </a>
                                    <a
                                        className="inline-flex justify-center items-center size-8 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:border-neutral-700 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                        href="#"
                                    >
                                        <svg
                                            className="shrink-0 size-3.5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M3.362 10.11c0 .926-.756 1.681-1.681 1.681S0 11.036 0 10.111C0 9.186.756 8.43 1.68 8.43h1.682v1.68zm.846 0c0-.924.756-1.68 1.681-1.68s1.681.756 1.681 1.68v4.21c0 .924-.756 1.68-1.68 1.68a1.685 1.685 0 0 1-1.682-1.68v-4.21zM5.89 3.362c-.926 0-1.682-.756-1.682-1.681S4.964 0 5.89 0s1.68.756 1.68 1.68v1.682H5.89zm0 .846c.924 0 1.68.756 1.68 1.681S6.814 7.57 5.89 7.57H1.68C.757 7.57 0 6.814 0 5.89c0-.926.756-1.682 1.68-1.682h4.21zm6.749 1.682c0-.926.755-1.682 1.68-1.682.925 0 1.681.756 1.681 1.681s-.756 1.681-1.68 1.681h-1.681V5.89zm-.848 0c0 .924-.755 1.68-1.68 1.68A1.685 1.685 0 0 1 8.43 5.89V1.68C8.43.757 9.186 0 10.11 0c.926 0 1.681.756 1.681 1.68v4.21zm-1.681 6.748c.926 0 1.682.756 1.682 1.681S11.036 16 10.11 16s-1.681-.756-1.681-1.68v-1.682h1.68zm0-.847c-.924 0-1.68-.755-1.68-1.68 0-.925.756-1.681 1.68-1.681h4.21c.924 0 1.68.756 1.68 1.68 0 .926-.756 1.681-1.68 1.681h-4.21z" />
                                        </svg>
                                    </a>
                                </div>
                                {/* End Social Brands */}
                            </div>
                        ))}
                    </div>
                    <div className="text-center mt-6">
                        <button
                            onClick={() => setShowAll(!showAll)}
                            className="px-6 py-2 text-sm font-semibold text-pink-600 bg-white border border-pink-600 rounded-md hover:bg-pink-600 hover:text-white flex items-center justify-center mx-auto"
                        >
                            {showAll ? 'View Less' : 'View More'}
                            <svg
                                className={`w-4 h-4 ml-2 transition-transform duration-300 ${showAll ? 'transform rotate-180' : ''
                                    }`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>


        <>
            <div className="container px-4 mx-auto mt-16  bg-[#f5f5f5]">
                <div className="max-w-sm">
                    {/* SearchBox */}
                    <div
                        className="relative"
                        data-hs-combo-box='{
    "groupingType": "default",
    "isOpenOnFocus": true,
    "apiUrl": "../assets/data/searchbox.json",
    "apiGroupField": "category",
    "outputItemTemplate": "<div data-hs-combo-box-output-item><span class=\"flex items-center cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200\"><div class=\"flex items-center w-full\"><div class=\"flex items-center justify-center rounded-full bg-gray-200 size-6 overflow-hidden me-2.5\"><img class=\"shrink-0\" data-hs-combo-box-output-item-attr=&apos;[{\"valueFrom\": \"image\", \"attr\": \"src\"}, {\"valueFrom\": \"name\", \"attr\": \"alt\"}]&apos; /></div><div data-hs-combo-box-output-item-field=\"name\" data-hs-combo-box-search-text data-hs-combo-box-value></div></div><span class=\"hidden hs-combo-box-selected:block\"><svg class=\"shrink-0 size-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"></polyline></svg></span></span></div>",
    "groupingTitleTemplate": "<div class=\"text-xs uppercase text-gray-500 m-3 mb-1 dark:text-neutral-500\"></div>"
  }'
                    >
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                                <svg
                                    className="shrink-0 size-4 text-gray-400 dark:text-white/60"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <circle cx={11} cy={11} r={8} />
                                    <path d="m21 21-4.3-4.3" />
                                </svg>
                            </div>
                            <input
                                className="py-3 ps-10 pe-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                type="text"
                                role="combobox"
                                aria-expanded="false"
                                placeholder="Type a name"
                                defaultValue=""
                                data-hs-combo-box-input=""
                            />
                        </div>
                        {/* SearchBox Dropdown */}
                        <div
                            className="absolute z-50 w-full bg-white border border-gray-200 rounded-lg dark:bg-neutral-800 dark:border-neutral-700"
                            style={{ display: "none" }}
                            data-hs-combo-box-output=""
                        >
                            <div
                                className="max-h-72 rounded-b-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
                                data-hs-combo-box-output-items-wrapper=""
                            />
                        </div>
                        {/* End SearchBox Dropdown */}
                    </div>
                    {/* End SearchBox */}
                </div>


                {/* Title */}
                <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-5">
                    <h2 className="text-2xl font-bold md:text-4xl md:leading-tight text-blue-600 dark:text-blue-600">
                        Genral Members
                    </h2>
                    <p className="mt-1 text-gray-800 dark:text-neutral-800">
                        Creative people
                    </p>
                </div>
                {/* End Title */}






                {/* Slider */}
                <div
                    data-hs-carousel='{
        "loadingClasses": "opacity-0",
        "isAutoPlay": true
    }'
                    className="relative "
                >
                    <div className="hs-carousel relative overflow-hidden  w-full h-screen  bg-white rounded-lg">
                        <div className="hs-carousel-body absolute top-0 bottom-0 start-0 flex flex-nowrap transition-transform duration-700 opacity-0">
                            <div className="hs-carousel-slide">
                                <div className="flex justify-center h-full bg-gray-200 p-6 dark:bg-neutral-900">
                                    {/* <span className="self-center text-4xl text-gray-800 transition duration-700 dark:text-white">
                                        First slide
                                    </span> */}

                                    <>
                                        {/* Grid */}
                                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 md:gap-12">
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        David Forren
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Founder / CEO
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Amil Evara
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Ebele Egbuna
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Maria Powers
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Director of sales
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Delia Pawelke
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Front-end Developer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1624224971170-2f84fed5eb5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Tom Lowry
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1579017331263-ef82f0bbc748?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Louise Donadieu
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1514222709107-a180c68d72b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Jeff Fisher
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Project Manager
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1602452920335-6a132309c7c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Sophia Harrington
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Project Manager
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1515621061946-eff1c2a352bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Christina Kray
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1514846226882-28b324ef7f28?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Amy Forren
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Product Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Philip Williams
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1520409364224-63400afe26e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Brian Lofoten
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1558507652-2d9626c4e67a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Jessica Dorsey
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Director of sales
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1521151716396-b2da27b1a19f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Nick Jackson
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                        </div>
                                        {/* End Grid */}
                                    </>

                                </div>
                            </div>
                            <div className="hs-carousel-slide">
                                <div className="flex justify-center h-full bg-gray-200 p-6 dark:bg-neutral-800">
                                    <>
                                        {/* Grid */}
                                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 md:gap-12">
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        David Forren
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Founder / CEO
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Amil Evara
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Ebele Egbuna
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Maria Powers
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Director of sales
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Delia Pawelke
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Front-end Developer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1624224971170-2f84fed5eb5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Tom Lowry
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1579017331263-ef82f0bbc748?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Louise Donadieu
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1514222709107-a180c68d72b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Jeff Fisher
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Project Manager
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1602452920335-6a132309c7c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Sophia Harrington
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Project Manager
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1515621061946-eff1c2a352bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Christina Kray
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1514846226882-28b324ef7f28?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Amy Forren
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Product Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Philip Williams
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1520409364224-63400afe26e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Brian Lofoten
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1558507652-2d9626c4e67a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Jessica Dorsey
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Director of sales
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1521151716396-b2da27b1a19f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Nick Jackson
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                        </div>
                                        {/* End Grid */}
                                    </>
                                </div>
                            </div>
                            <div className="hs-carousel-slide">
                                <div className="flex justify-center h-full bg-gray-200 p-6 dark:bg-neutral-700">
                                    <>
                                        {/* Grid */}
                                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 md:gap-12">
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        David Forren
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Founder / CEO
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Amil Evara
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Ebele Egbuna
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Maria Powers
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Director of sales
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Delia Pawelke
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Front-end Developer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1624224971170-2f84fed5eb5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Tom Lowry
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1579017331263-ef82f0bbc748?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Louise Donadieu
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1514222709107-a180c68d72b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Jeff Fisher
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Project Manager
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1602452920335-6a132309c7c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Sophia Harrington
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Project Manager
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1515621061946-eff1c2a352bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Christina Kray
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1514846226882-28b324ef7f28?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Amy Forren
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Product Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Philip Williams
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Support Consultant
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1520409364224-63400afe26e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Brian Lofoten
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1558507652-2d9626c4e67a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Jessica Dorsey
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        Director of sales
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                            <div className="text-center">
                                                <img
                                                    className="rounded-full size-24 mx-auto"
                                                    src="https://images.unsplash.com/photo-1521151716396-b2da27b1a19f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                                    alt="Avatar"
                                                />
                                                <div className="mt-2 sm:mt-4">
                                                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                                                        Nick Jackson
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                        UI/UX Designer
                                                    </p>
                                                </div>
                                            </div>
                                            {/* End Col */}
                                        </div>
                                        {/* End Grid */}
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="hs-carousel-prev hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 start-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/10 focus:outline-none focus:bg-gray-800/10 rounded-s-lg dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                    >
                        <span className="text-2xl" aria-hidden="true">
                            <svg
                                className="shrink-0 size-5"
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="m15 18-6-6 6-6" />
                            </svg>
                        </span>
                        <span className="sr-only">Previous</span>
                    </button>
                    <button
                        type="button"
                        className="hs-carousel-next hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 end-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/10 focus:outline-none focus:bg-gray-800/10 rounded-e-lg dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                    >
                        <span className="sr-only">Next</span>
                        <span className="text-2xl" aria-hidden="true">
                            <svg
                                className="shrink-0 size-5"
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="m9 18 6-6-6-6" />
                            </svg>
                        </span>
                    </button>
                    <div className="hs-carousel-pagination flex justify-center absolute bottom-3 start-0 end-0 space-x-2">
                        <span className="hs-carousel-active:bg-blue-700 hs-carousel-active:border-blue-700 size-3 border border-gray-400 rounded-full cursor-pointer dark:border-neutral-600 dark:hs-carousel-active:bg-blue-500 dark:hs-carousel-active:border-blue-500" />
                        <span className="hs-carousel-active:bg-blue-700 hs-carousel-active:border-blue-700 size-3 border border-gray-400 rounded-full cursor-pointer dark:border-neutral-600 dark:hs-carousel-active:bg-blue-500 dark:hs-carousel-active:border-blue-500" />
                        <span className="hs-carousel-active:bg-blue-700 hs-carousel-active:border-blue-700 size-3 border border-gray-400 rounded-full cursor-pointer dark:border-neutral-600 dark:hs-carousel-active:bg-blue-500 dark:hs-carousel-active:border-blue-500" />
                    </div>
                </div>
                {/* End Slider */}
            </div>
        </>

    </>
    );
}
