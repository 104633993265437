import React from "react";
import { Link } from "react-router-dom";
export function Disclaimer() {
    return (<>

        <section className="text-gray-800 body-font mt-16 bg-[#f5f5f5]">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-col text-center w-full mb-20">

                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-blue-600">
                        Disclaimers
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed  text-base">
                        While we aim to keep the information accurate and up-to-date, we do not make any representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information, products, services, or related graphics contained on the website for any purpose
                    </p>
                </div>
            </div>
        </section>


        <div className="bg-vision bg-cover bg-center mt-16  bg-no-repeat my-auto bg-[#f5f5f5] text-white flex justify-center items-center lg:md:sm:h-[80vh] h-[65vh] w-full ml-5">
            <div className="lg:md:m-20 flex items-center justify-center">
                <img src="https://plus.unsplash.com/premium_photo-1661380270467-1aa98000a421?q=80&w=1332&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
            </div>
            <div className="lg:md: m-5 lg:ml-0">
                <h2 className="font-bold text-2xl" />
                <div className=" p-2  ml-0">
                    <div>
                        <h3 className="font-bold text-xl text-blue-600">Membership</h3>
                        <hr className="h-1 w-24 bg-nile-blue-500 rounded-full my-1" />
                        <p className=" text-sm text-gray-800">
                            Becoming a member of APNA is subject to approval based on our
                            criteria. The first three months of membership are free of charge, and
                            the next membership fees are applicable as per the chosen plan. APNA
                            reserves the right to modify membership terms and fees at any time
                            without earlier notice.{" "}
                        </p>
                    </div>
                    <div>
                        <h3 className="font-bold text-xl text-blue-600">Payment and Discounts</h3>
                        <hr className="h-1 w-24 bg-nile-blue-500 rounded-full my-1" />
                        <p className=" text-sm text-gray-800">
                            APNA provides various payment methods for paid plans, including
                            credit/debit cards, bank transfers, and mobile payments. Discounts on
                            paid plans are subject to terms and conditions and may change without
                            notice. Please refer to our website for the most current information
                            on discounts and promotions.
                        </p>
                    </div>
                    <div>
                        <h3 className="font-bold text-xl text-blue-600">Data Protection and Privacy</h3>
                        <hr className="h-1 w-24 bg-nile-blue-500 rounded-full my-1" />
                        <p className=" text-sm text-gray-800">
                            APNA is committed to protecting your privacy. Any personal information
                            collected through this website will be used under our privacy policy.
                            We take all reasonable steps to ensure the security of your data, but
                            cannot guarantee its absolute security.{" "}
                        </p>
                    </div>
                    <div>
                        <h3 className="font-bold text-xl text-blue-600">Intellectual Property</h3>
                        <hr className="h-1 w-24 bg-nile-blue-500 rounded-full my-1" />
                        <p className=" text-sm text-gray-800">
                            All content on this website, including text, graphics, logos, and
                            images, is the property of APNA and is protected by copyright laws.
                            Unauthorized use or reproduction of this content is prohibited.{" "}
                        </p>
                    </div>
                </div>
                <div className="font-bold text-xl m-5 mx-0 px-2 ml-0 mb-0" />
                <div className=" p-2" />
            </div>
        </div>





        <section className="text-gray-600 body-font bg-[#f5f5f5] mt-10">
            <div className="container px-5 py-24 mx-auto">
                <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
                    <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">
                        Join APNA today to connect with industry leaders, gain valuable support, and help shape the future of Pakistan's network infrastructure.
                    </h1>
                    <Link to={'/register'}>
                        <button className="flex-shrink-0  rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400 text-lg mt-10 sm:mt-0">
                            Button
                        </button>
                    </Link>
                </div>
            </div>
        </section>






        <div className="lg:md:px-32 lg:md:grid grid-cols-2 bg-[#f5f5f5] mt-16 flex flex-col pl-5 pb-10 items-center justify-center my-auto   h-full w-full lg:md:sm:pr-0 mr-0">
            <div className="flex flex-col lg:md:sm:mx-10 ">
                <div className="font-bold text-xl ml-0 mx-5">
                    <h2 />
                </div>
                <div className="m-5 ml-0">
                    <div>
                        <h3 className="font-bold text-xl text-blue-600">Service Availability</h3>
                        <hr className="h-1 w-20 bg-nile-blue-500 mt-2" />
                        <p className=" text-sm text-gray-800">
                            While we desire to ensure the website is available 24/7, we do not
                            guarantee uninterrupted access. Maintenance, updates, or technical
                            issues may occasionally result in temporary downtime. APNA is not
                            responsible for any inconvenience or loss caused by such disruptions.{" "}
                        </p>
                    </div>
                    <div>
                        <h3 className="font-bold text-xl text-blue-600">Accuracy of Information</h3>
                        <hr className="h-1 w-20 bg-nile-blue-500 mt-2" />
                        <p className=" text-sm text-gray-800">
                            The information on our website is provided "as is" without any
                            guarantees of completeness, accuracy, or timeliness. APNA does not
                            accept any responsibility for errors or omissions. Users are
                            encouraged to verify any information before relying on it.
                        </p>
                    </div>
                    <div>
                        <h3 className="font-bold text-xl text-blue-600">Changes to This Disclaimer</h3>
                        <hr className="h-1 w-20 bg-nile-blue-500 mt-2" />
                        <p className=" text-sm text-gray-800">
                            APNA reserves the right to update or change these disclaimers at any
                            time. Any changes will be posted on this page. It is your
                            responsibility to review this page periodically for updates.
                        </p>
                    </div>
                    <div>
                        <h3 className="font-bold text-xl text-blue-600">Contact Us </h3>
                        <hr className="h-1 w-20 bg-nile-blue-500 mt-2" />
                        <p className=" text-sm text-gray-800">
                            If you have any questions about these disclaimers, please contact us
                            at support@apna.org.pk or call our helpline at +92-300-0000000.
                        </p>
                    </div>
                </div>
                <div />
            </div>
            <div className="bg-vision bg-cover bg-center  bg-no-repeat my-auto  text-white flex justify-center items-center lg:md:sm:h-[80vh] h-[65vh] w-full ml-5">
                {/* <p className="font-bold text-3xl text-end ">Disclaimer</p> */}
                <img src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
            </div>



        </div>



    </>)
}