import React from 'react'
// import { A } from './A';
// import { B } from './B';
// import { C } from './C';
// import { A } from '../Comp/Home/A'
import { A } from './A'
import  { B } from './B'
import  { C } from './C'

export function Home() {
  return (
    <>
      <A />
      <div className="p-8 dark:border-gray-700">
        <B />
      </div>
      <C />
    </>
  )
}













// import { useEffect } from 'react';

// export function Home () {
//   useEffect(() => {
//     const section = document.querySelector('.animated-section');

//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             entry.target.classList.add('slide-in-left');
//           } else {
//             entry.target.classList.remove('slide-in-left');
//           }
//         });
//       },
//       { threshold: 0.1 } 
//     );

//     if (section) {
//       observer.observe(section);
//     }

//     return () => {
//       if (section) {
//         observer.unobserve(section);
//       }
//     };
//   }, []);

//   return (
//     <section className="absolute top-0 w-full h-screen section-background animated-section">
//       <div className="absolute inset-0 sm:bg-transparent sm:ltr:bg-gradient-to-r sm:rtl:bg-gradient-to-l" />
//       <section className="text-white">
//         <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
//           <div className="mx-auto max-w-3xl text-center">
//             <h1 className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-2xl font-extrabold text-transparent sm:text-1xl">
//               APNA, Union excels in delivering excellence in internet and network services and equipment, fueling your digital ambitions.
//               <span className="sm:block"> Increase Conversion. </span>
//             </h1>
//             <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
//               Uncover the power of Union's rebellious solutions to voice the true potential of your digital world.
//             </p>
//             <div className="mt-8 flex flex-wrap justify-center gap-4">
//               <a
//                 className="block w-full rounded border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
//                 href="#"
//               >
//                 Get Started
//               </a>
//               <a
//                 className="block w-full rounded border border-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
//                 href="#"
//               >
//                 Learn More
//               </a>
//             </div>
//           </div>
//         </div>
//       </section>
//     </section>
//   );
// };







{/* <section className="absolute top-0 w-full h-screen section-background">
  <div className="absolute inset-0  sm:bg-transparent s ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l" />
  <section className=" text-white">
    <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
      <div className="mx-auto max-w-3xl text-center">
        <h1 className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-2xl font-extrabold text-transparent sm:text-1xl">
          APNA, Union excels in delivering excellence in internet and network services and equipment, fueling your digital ambitions.
          <span className="sm:block"> Increase Conversion. </span>
        </h1>
        <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
          Uncover the power of Union's rebellious solutions to voice the true potential of your digital world.
        </p>
        <div className="mt-8 flex flex-wrap justify-center gap-4">
          <a
            className="block w-full rounded border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
            href="#"
          >
            Get Started
          </a>
          <a
            className="block w-full rounded border border-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
            href="#"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  </section>

</section> */}